<template>
  <div class="ActivityCenter">
    <!-- 头部导航 -->
    <Topbar
      skip1="UserInfo"
      skip2="Teamwork"
      skip3="FAQ"
      skip4="ActivityCenter"
    />
    <div class="content">
      <div class="content-header">
        <div class="left">{{ $t("活動") }}</div>
        <div class="right">
          <span class="sp1">ACT</span>
          <span class="sp2">{{ $t("中心") }}</span>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="activity">
        <ul>
          <li v-for="item in bannerList" :key="item.id" @click="linkTo(item)">
            <div class="activity-img">
              <img :src="BigImg(item.url_pc)" alt="" />
            </div>
            <div class="activity-content">
              <div class="activity-content-left">
                <div class="sp1">
                  {{ item.url_type ? "官網直充加贈點數" : "G18大禮包特權碼" }}
                </div>
                <div class="sp2">
                  {{ item.url_type ? "活动时间7/8-7/21" : "請在遊戲內輸入" }}
                </div>
              </div>
              <div v-if="item.url_type" class="activity-content-right">
                {{ "立即充值" }}
              </div>
              <div v-else></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 底部栏 -->
    <Botbar />
    <Dialog ref="download" />
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Botbar from "@/components/Botbar";
export default {
  components: {
    Topbar,
    Botbar,
  },
  data() {
    return {
      bannerList: [],
    };
  },
  created() {
    this.getHomeData();
  },
  methods: {
    BigImg(item) {
      if (item.indexOf("-big") > 0) {
        this.bigimg = item;
      } else {
        var hz = item.slice(item.lastIndexOf("."));
        var img = item.substring(0, item.lastIndexOf("."));
        this.bigimg = img + "-big" + hz;
      }
      return this.bigimg;
    },
    linkTo(item) {
      if (item.url_type) {
        this.getViewCount(3004, item.id);
        this.$router.push(item.url);
      } else {
        this.$refs.download.dialog2 = true;
        this.$refs.download.text1 = "温馨提示";
        this.$refs.download.text2 = "請下載該遊戲、於遊戲內兌換！";
        this.getViewCount(3004, item.id);
      }
    },
    // 获取首页数据
    getHomeData() {
      this.$get("/api/Home/Data").then((res) => {
        // this.bannerList = res.data.banners;
        res.data.banners.map((item) => {
          if (item.url_type) {
            this.bannerList.push(item);
            console.log(this.bannerList);
          }
          if (item.banner_type == 1) {
            this.bannerList.push(item);
            console.log(this.bannerList);
          }
        });
      });
      console.log(this.bannerList);
    },
    // 获取点击浏览记录
    getViewCount(code, id) {
      this.$post("/api/Home/G18ClickDown", {
        code: code,
        game_id: id,
      }).then((res) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.ActivityCenter {
  margin-top: 70px;
  .content {
    padding-top: 30px;
    height: calc(100vh - 268px);
    max-width: 1100px;
    margin: 0 auto;
    .content-header {
      display: flex;
      align-items: center;
      .left {
        font-size: 28px;
        font-weight: 500;
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          line-height: 1;
          font-size: 16px;
        }
        .sp1 {
          color: #c8c8c8;
        }
      }
    }
    .activity {
      ul {
        padding: 50px 0 0 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
          cursor: pointer;
          background: #ffffff;
          box-shadow: 0px 20px 20px rgba(230, 236, 234, 0.3);
          width: 30%;
          margin-bottom: 30px;
          &:not(:nth-child(3n)) {
            margin-right: calc(10% / 2);
          }
          &:hover .activity-img img {
            transform: scale(1.8);
          }
          .activity-img {
            img {
              display: block;
              width: 100%;
              transition: all 0.6s;
            }
            // img:hover {
            // }
          }
          .activity-content {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .activity-content-left {
              text-align: left;
              .sp1 {
                font-size: 16px;
                color: #333333;
              }
              .sp2 {
                font-size: 14px;
                color: #22c907;
              }
            }
            .activity-content-right {
              padding: 5px 15px;
              font-size: 14px;
              color: #fff;
              background: #f56490;
              border-radius: 20px;
            }
          }
        }
      }
    }
  }
}
</style>