<template>
  <div style="width: 797px;">
    <v-carousel cycle :show-arrows="false" height="446"  class="rounded-xl">
      <v-carousel-item v-for="(item, i) in bannerList" :key="i" >
        <v-img   @click=" gotoGame(
              item.url,
              item.url_type,
              item.banner_type,
              parseInt(item.id)
            );"    :src="BigImg(item.url_pc)" alt=""></v-img>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
let vm = null;
export default {
  components: {},
  props: ["bannerList"],

  data() {
    return {

    };
  },
  created() {
    vm = this;
    this.$nextTick(() => { });
  },
  methods: {
    to(index) {
      this.swiper.slideTo(index);
      console.log(this.swiper);
    },
    BigImg(item) {
      if (item.indexOf("-big") > 0) {
        this.bigimg = item;
      } else {
        var hz = item.slice(item.lastIndexOf("."));
        var img = item.substring(0, item.lastIndexOf("."));
        this.bigimg = img + "-big" + hz;
      }
      return this.bigimg;
    },
    gotoGame(url, url_type, banner_type, banner_id) {
      this.$emit("gotoGame",url, url_type, banner_type, banner_id)
      return;
      clearTimeout(this.timer);
      if (!url) return;
      if (url_type) {
        this.$post("/api/Home/G18ClickDown", {
          code: 3004,
          game_id: banner_id,
        }).then((res) => { });
        this.$router.push(url);
      } else {
        if (banner_type == 1) {
          this.$refs.download.dialog2 = true;
          this.$refs.download.text1 = "温馨提示";
          this.$refs.download.text2 = "請下載該遊戲、於遊戲內兌換！";
          this.$post("/api/Home/G18ClickDown", {
            code: 3004,
            game_id: banner_id,
          }).then((res) => { });
          return;
        }
        window.open(url); // 获取点击浏览记录
        this.$post("/api/Home/G18ClickDown", {
          code: 3004,
          game_id: banner_id,
        }).then((res) => { });
      }
    },
  },
  //计算属性
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  mounted() { },
};
</script>

<style lang="scss" scoped></style>