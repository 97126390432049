import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入svg
import "@/assets/icons";

import "./components";
import Toast from "./components/toast";

// 引入vuetify
import "./plugins";
import vuetify from "@/plugins/vuetify";
import axios from 'axios'

import i18n from "./i18n"; // 引入i18n实例
// 引入全局重置样式
import "@/assets/css/reset.css";
Vue.use(Toast);
Vue.config.productionTip = false;

//获取可用api地址
var domains =process.env.VUE_APP_API_BASE_URLS.split(",")
function checkDomainAsync() {
  var flag=false
  return new Promise((resove, reject) => {
      window.ds = [];
      window.num = 0;
      domains.forEach((domain, i) => {
          setTimeout(function () {
              var t1 = performance.now();
              if(flag) return
              $.ajax({
                  url:domain + '/jsonp.js',
                  jsonp: 'cb',
                  async: false,
                  jsonpCallback: 'fn',
                  dataType: 'jsonp',
                  success: function (response) {
                      if (response.name == "ok") {
                          var sub = performance.now() - t1;
                          window.ds.push({ domain, time: sub });
                          flag=true;
                      }
                  },
                  error: function (e) {
                      console.log(e);
                  },
                  complete: function (XMLHttpRequest, textStatus) {
                      window.num++;
                  },
                  timeout: 3000
              })
          }, 100*i)
      })
      var timer = setInterval(() => {
          //if(window.num==domains.length){
          if (ds.length > 0||window.num==domains.length) {
              clearInterval(timer)
              var domain;
              if (ds.length > 0) {
                  ds.sort((a, b) => {
                      return a.time > b.time ? 1 : -1;
                  })
                  domain = ds[0].domain
              }
              resove(domain)
          }
      }, 10)
  })
}

checkDomainAsync().then(c=>{
  if(c)axios.defaults.baseURL=c
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
})
