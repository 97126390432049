<template>
  <div class="index">
    <!-- 头部导航 -->
    <Topbar />
    <!-- 中间内容部分 -->
    <div class="content" style="height: calc(100vh - 432px);">
      <div >
        <img style="width: 34px;height: 38px;cursor: pointer;" @click="$router.push('/forgetpassword')" :src='require("@/assets/imgs/back.png")' alt="">
      </div>
      <div class="box" v-if="!showReset" >
        <div>{{ $t("憑證上傳") }}</div>
        <div style="background: rgba(255, 255, 255, 0.8);border: 1px dashed #C5D4DB;">
          <input type="file" class="img-upload-input" id="img-upload-input"
            accept="image/jpg, image/jpeg, image/png, image/gif" @change="uploadImage" />
          <img :src="UploadImg" alt="" />
          <div class="tips">{{ $t("請上傳您的帳號憑證") }}</div>
          <div class="upload">{{ $t("上傳") }}</div>
        </div>
      </div>
      <div v-else class="box">
        <div>{{ $t("重置密码") }}</div>
        <!-- 重置密码 -->
        <v-card elevation="0">
          <div style="margin-top: 56px;font-size: 25px;line-height: 20px;color: #485573;">{{ $t("二維碼識別成功請重新設置您的密碼") }}
          </div>
          <v-form ref="form" style="width: 583px;height: 439px;margin-top: 32px;">
            <v-text-field readonly color="info" outlined v-model="account" prepend-inner-icon="mdi-account"
              class="rounded-lg"></v-text-field>
            <v-text-field color="info" prepend-inner-icon="mdi-lock" :placeholder="$t('請輸入密碼')" outlined clearable
              v-model="password1" :type="'password'" :rules="[rules.required, rules.counter, rules.password1]"
              class="rounded-lg"></v-text-field>
            <v-text-field color="info" prepend-inner-icon="mdi-lock" :placeholder="$t('請再次輸入密碼')" outlined clearable
              v-model="password2" :type="'password'" :rules="[rules.required, rules.password2]"
              class="rounded-lg"></v-text-field>
            <v-btn height="60" block class="rounded-lg mt-1 btn2" bottom @click="resetPassword">
              {{ $t("確定") }}
            </v-btn>
          </v-form>
        </v-card>
      </div>
    </div>
    <canvas id="qrcanvas" width="390" height="844" style="display: none"></canvas>
    <Dialog ref="hintDialog" />
    <!-- 底部栏 -->
    <Botbar />
  </div>
</template>
<script>
import Topbar from "@/components/Topbar";
import Botbar from "@/components/Botbar";
import i18n from "@/i18n/index";
import QrCode from "qrcode-decoder";
import jsQR from "jsqr";
export default {
  components: {
    Topbar,
    Botbar,
  },
  data() {
    return {
      UploadImg: require("@/assets/imgs/upload.png"),
      safedata: "",
      account: "",
      password1: "",
      password2: null,
      showReset: false,
      base64Data: null,
      key: "",
      rules: {
        required: (value) => !!value || i18n.t("必填項"),
        counter: (value) =>
          (value && value.length >= 6 && value.length <= 20) ||
          i18n.t("密碼必須為6-20位英文+數字"),
        password1: (value) => {
          const pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
          return pattern.test(value) || i18n.t("密碼格式英文+數字6-20位");
        },
        password2: (value) =>
          value === this.password1 || i18n.t("兩次密碼不一致"),
      },
    };
  },
  methods: {
    // 重置密码
    resetPassword() {
      if (this.$refs.form.validate()) {
        this.$post("/Api/Account/ResetPassword", {
          Password: this.password1,
          ConfirmPassword: this.password2,
          Key: this.key,
        }).then((res) => {
          this.$refs.hintDialog.dialog2 = true;
          this.$refs.hintDialog.text1 = i18n.t("溫馨提示");
          this.$refs.hintDialog.text2 = i18n.t("您的密碼已修改，請重新登錄");
          this.$refs.hintDialog.skipOrder = "/login";
        });
      }
    },

    uploadImage(e) {
      //  图片数量校验
      if (e.target.files.length > 1) {
        this.$toast(i18n.t("最多選擇1張憑證"));
        return;
      }
      if (window.FileReader) {
        var file = e.target.files[0];
        var fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onloadend = (e) => {
          this.base64Data = e.target.result;
          this.resolveQR(this.base64Data);
        };
      }
    },

    // 二维码识别  使用时触发的函数
    resolveQR(data) {
      var c = document.getElementById("qrcanvas");
      var ctx = c.getContext("2d");
      var width = 390;
      var height = 844;
      var img = new Image();
      img.src = data;
      img.onload = () => {
        ctx.drawImage(img, 0, 0, width, height);
        var imageData = ctx.getImageData(0, 0, width, height);
        const code = jsQR(imageData.data, width, height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          console.log(code);
          //二维码内容
          code.data = JSON.parse(code.data);
          this.account = code.data.account;
          this.key = code.data.key;
          if (!this.account || !this.key) {
            this.$toast(i18n.t("二維碼識別失敗，請重新上傳"));
            return;
          }
          this.showReset = true;

          this.$toast(i18n.t("二維碼識別成功"));
        } else {
          this.$toast(i18n.t("二維碼識別失敗，請重新上傳"));
        }
      };
    },
  },
};
</script>


<style lang="scss" scoped>
.index {
  margin-top: 60px;
  background: #F0F6F9;

  .content {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;

    width: 1124px;
    margin: 60px auto 190px auto;
    text-align: left;

    .box {
      width: 672px;
      margin: 0 auto;

      >:nth-child(1) {
        font-size: 20px;
        line-height: 100%;
        /* identical to box height, or 20px */

        display: flex;
        align-items: center;
        text-align: center;
        color: #00C7DB;
      }

      >:nth-child(2) {
        cursor: pointer;
        margin-top: 25px;
        height: 450px;
        background: transparent;
        border-radius: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
          cursor: pointer;
          position: absolute;
          z-index: 2;
          width: 755px;
          height: 450px;
          opacity: 0;
        }

        img {
          margin-top: 119px;
          width: 107px;
          height: 94px;
          margin-bottom: 17px;
        }

        .tips {
          font-size: 18px;
          line-height: 15px;
          color: #666666;
          margin-bottom: 52px;
        }

        .btn2,.upload {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px;
          width: 316px;
          height: 76px;

          background: #F84E84;
          box-shadow: 0px 15px 30px rgba(245, 86, 136, 0.1);
          border-radius: 10px;

          font-size: 20px;
          line-height: 20px;

          color: #FFFFFF;

        }
      }
    }

  }
}
</style>
