<template>
  <div class="Privacy">
    <!-- 头部导航 -->
    <Topbar
      skip1="UserInfo"
      skip2="Teamwork"
      skip3="FAQ"
      @removeRecommend="removeRecommend()"
      @removeNewgame="removeNewgame()"
    />
    <div class="content">
      <div class="content-title">{{ $t("隱私權條款") }}</div>
      <div>
        <ul>
          <li>
            <h3>{{ $t("隱私權保護政策的適用範圍") }}</h3>
            <p>
              {{
                $t(
                  "隱私權保護政策適用於使用G18服務的所有使用者。G18將基於隱私權保護政策，嚴加管理和保護使用者提交的個人情報。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "隱私權保護政策不適用於未使用G18服務的個人，及與G18各服務無關的個人或企業組織等。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("情報的收集與利用") }}</h3>
            <p>
              {{
                $t(
                  "使用者向G18提交的個人情報，是爲了使用G18服務所需的最低限度的必要情報。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "G18將使用者的IP位置、Cookies資訊、閲覽的頁面、行動裝置的資訊、電腦的使用環境等情報，從使用者使用的瀏覽器或應用程式中自動讀取後，記錄在伺服器中。"
                )
              }}
            </p>
            <p>
              {{ $t("G18要求使用者提供個人情報的目的如下：") }}
            </p>
            <p>
              {{ $t("1、爲了改善G18服務質量。") }}
            </p>
            <p>
              {{ $t("2、爲了向使用者提供G18的最新情報以及各項通知。") }}
            </p>
            <p>
              {{ $t("3、爲了在必要時聯絡使用者。") }}
            </p>
            <p>
              {{ $t("4、爲了向使用者提供更加舒適的使用環境。") }}
            </p>
          </li>

          <li>
            <h3>{{ $t("情報的公開") }}</h3>
            <p>
              {{ $t("G18不會向任何人出售或出借使用者個人情報。") }}
            </p>
            <p>
              {{
                $t(
                  "在以下的情況下，G18會向政府機關、其他人士或公司提供使用者個人情報。"
                )
              }}
            </p>
            <p>
              {{
                $t("1、受司法機關，警察署或其他有權機關基於法定程序之要求時。")
              }}
            </p>
            <p>
              {{ $t("2、當生命財產受到威脅、或者被認爲有緊急必要時。") }}
            </p>
            <p>
              {{
                $t(
                  "3、公開或者與他人共用個人情報，才能提供給使用者所需的服務時。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "4、向G18委託的公司公開個人情報，才能提供給使用者所需的服務時。 (這些公司均無權使用我們提供的個人情報，作提供產品或服務以外的其他用途)"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "5、當使用者違反了G18的使用條款，或者爲了保護G18的權利、財產、服務等情況下。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("有關安全性") }}</h3>
            <p>
              {{ $t("爲了保障使用者的隱私及安全，帳號將會用密碼保護。") }}
            </p>
            <p>
              {{
                $t(
                  "G18在必要的情況下，會使用通行標準的SSL保全系統，保障資料傳送的安全。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("有關隱私權保護政策的修訂") }}</h3>
            <p>
              {{
                $t(
                  "今後，G18將不時的修改隱私權保護政策的一部或全部内容。（進行重大修改時，將會在G18網頁上張貼告示，通知相關事項。）"
                )
              }}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 底部栏 -->
    <Botbar />
  </div>
</template>

<script>
// 引入i18n
import i18n from "@/i18n/index";
import Topbar from "@/components/Topbar";
import Botbar from "@/components/Botbar";
export default {
  components: {
    Topbar,
    Botbar,
  },
  data() {
    return {};
  },
  methods: {
    removeRecommend() {
      this.$router.push("/");
    },
    removeNewgame() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.Privacy {
  margin-top: 70px;
  .content {
    max-width: 1100px;
    margin: 30px auto;
    .content-title {
      padding: 10px 0 10px 10px;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      background: linear-gradient(88.91deg, #ff5285 53.62%, #fc8db4 153.54%);
    }
    ul {
      padding: 0;
      li {
        text-align: left;
        margin: 20px 0;

        h3 {
          padding-left: 15px;
          font-size: 20px;
          font-weight: 700;
        }
        p {
          padding-left: 35px;
          margin: 0;
        }
      }
    }
  }
}
</style>