<template>
  <div class="index">
    <!-- 头部导航 -->
    <Topbar />
    <!-- 中间内容部分 -->
    <div class="content" style="height: calc(100vh - 432px);">
      <div>
        <img style="width: 34px;height: 38px;cursor: pointer;" @click="back" :src='require("@/assets/imgs/back.png")' alt="">
      </div>
      <div class="box">
        <div class="left" v-if="switchActive">
          <div style="display: flex;height: 44px;line-height: 44px;">
            <div style="border-bottom: 2px solid #F84E84;cursor: pointer;" @click="switchActive = !switchActive"> {{
              $t("註冊") }}</div>
            <div style="border: 1px solid #DBE7EC;transform: rotate(90deg);width: 22px;height: 0px;margin: 24px 20px 0;">
            </div>
            <div style="color:#9FB8C5 ;cursor: pointer;" @click="switchActive = !switchActive"> {{ $t("快速註冊") }}</div>
          </div>
          <v-form class="my-5" ref="form">
            <v-text-field :placeholder="$t('請輸入用戶名')" color="info" outlined clearable v-model="account"
              :rules="[rules.required, rules.account]" prepend-inner-icon="mdi-account" class="rounded-lg"></v-text-field>
            <v-text-field color="info" prepend-inner-icon="mdi-lock" :placeholder="$t('請輸入密碼')" outlined clearable
              v-model="password1" :type="'password'" :rules="[rules.required, rules.counter, rules.password1]"
              class="rounded-lg"></v-text-field>
            <v-text-field color="info" prepend-inner-icon="mdi-lock" :placeholder="$t('請再次輸入密碼')" outlined clearable
              v-model="password2" :type="'password'" :rules="[rules.required, rules.password2]"
              class="rounded-lg"></v-text-field>
            <v-btn height="60" block class="rounded-lg mt-1 btn1" bottom @click="validate"
              :class="account && password1 && password2 ? 'actived' : ''">
              {{ $t("確定") }}
            </v-btn>
          </v-form>
          <div>{{$t("註冊會員，視同同意")}}<span @click="$router.push('Privacy')">{{$t("隱私權條款")}}</span></div>
        </div>
        <div v-else class="left">
          <div style="display: flex;height: 44px;line-height: 44px;">
            <div style="color:#9FB8C5 ;cursor: pointer;" @click="switchActive = !switchActive"> {{ $t("註冊") }}</div>
            <div style="border: 1px solid #DBE7EC;transform: rotate(90deg);width: 22px;height: 0px;margin: 24px 20px 0;">
            </div>
            <div style="border-bottom: 2px solid #F84E84;cursor: pointer;" @click="switchActive = !switchActive"> {{
              $t("快速註冊") }}</div>
          </div>
          <v-form class="my-5" ref="form">
            <v-text-field readonly :placeholder="$t('用戶名') + ':' + userID" background-color="#EEEBEB" color="info"
              outlined clearable v-model="quickaccount" class="rounded-lg"></v-text-field>
            <v-text-field readonly color="info" background-color="#EEEBEB" :placeholder="$t('密碼') + ':' + randomPsword"
              outlined clearable v-model="quickpassword" class="rounded-lg"></v-text-field>
            <v-btn height="60" block class="rounded-lg mt-1 btn1" bottom @click="fastvalidate" style="opacity: 1;">
              {{ $t("確定") }}
            </v-btn>
          </v-form>
          <div class="q1"> {{ $t("為防止帳號丟失") }}</div>
          <div class="q2">{{ $t("請截圖保存您的用戶名及密碼") }}</div>
          <div class="q3" @click="fastvalidate" >{{ $t("生成個人帳號憑證") }}</div>
        </div>
        <div class="right">
          <div> {{ $t("已有帳號") }}</div>
          <div @click="$router.push('/login')"> {{ $t("登錄帳號") }}</div>
        </div>
      </div>
    </div>
    <Dialog2 ref="hintDialog" @fastLogin="fastLogin"/>
    <!-- 底部栏 -->
    <Botbar />
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Botbar from "@/components/Botbar";
import i18n from "@/i18n/index";
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
    Topbar,
    Botbar,
  },
  data() {
    return {
      //控制右边二维码显示
      QRcodeShow: false,
      switchActive: true,
      text: "none",
      userID: "",
      randomPsword: "",
      isshow: true,
      account: "",
      quickaccount: "",
      quickpassword: "",
      password1: "",
      password2: null,
      rules: {
        required: (value) => !!value || i18n.t("必填項"),
        counter: (value) =>
          (value && value.length >= 6 && value.length <= 20) ||
          i18n.t("密碼必須為6-20位英文+數字"),
        account: (value) => {
          const pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
          return pattern.test(value) || i18n.t("用戶名英文+數字6-20位");
        },
        password1: (value) => {
          const pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
          return pattern.test(value) || i18n.t("密碼格式英文+數字6-20位");
        },
        password2: (value) =>
          value === this.password1 || i18n.t("兩次密碼不一致"),
      },
    };
  },
  created() {
    const str1 = Math.random().toString(36).substr(2, 8);
    const str2 = Math.random().toString(36).substr(2, 8);
    this.userID = str1;
    this.randomPsword = str2;
  },
  methods: {
    back(){
      history.back();
    },
    // 快速注册
    fastLogin() {
      this.$post("/api/Account/Login", {
        account: this.userID,
        password: this.randomPsword,
        gameId: process.env.VUE_APP_API_BASE_GameId,
      }).then((res) => {
        this.$toast(i18n.t("登陸成功"));
        localStorage.setItem("token", res.data.token);
        this.$router.push("/");
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$post("/Api/Account/Register", {
          account: this.account,
          Password: this.password1,
          ConfirmPassword: this.password2,
          gameId: process.env.VUE_APP_API_BASE_GameId,
        }).then((res) => {
          this.$toast(i18n.t("註冊成功"));
          this.$router.push("/login");
        });
      }
    },
    // 快速注册
    fastvalidate() {
      this.$post("/Api/Account/Register", {
        account: this.userID,
        password: this.randomPsword,
        confirmPassword: this.randomPsword,
        gameId: process.env.VUE_APP_API_BASE_GameId,
      }).then((res) => {
        
        this.$toast(i18n.t("註冊成功"));
        this.text = JSON.stringify({
          account: this.userID,
          key: res.data.key,
        });
        this.$refs.hintDialog.dialog2 = true;
        this.$refs.hintDialog.text = this.text;
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.index {
  margin-top: 60px;
  background: #F0F6F9;

  .content {
    font-family: 'PingFang SC';
    font-style: normal;

    position: relative;
    width: 1124px;
    margin: 60px auto 190px auto;
    text-align: left;

    .box {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;

      .left {
        width: 700px;
        height: 600px;
        padding: 49px 59px 51px 58px;

        background: rgba(255, 255, 255, 0.7);
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(30px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 20px;

        >:nth-child(1) {
          font-size: 20px;
          line-height: 100%;
          color: #00C7DB;
          margin-bottom: 32px;
        }

        >:nth-child(2) {
          font-size: 20px;
          line-height: 100%;
          color: #00C7DB;
          margin-bottom: 32px;
        }


        button {
          margin-bottom: 47px;
          font-size: 20px;
          line-height: 20px;
          color: #FFFFFF;
          background: linear-gradient(91.69deg, #F84077 16.39%, #F95E93 139.39%);
          opacity: 0.5;
          box-shadow: 0px 15px 30px rgba(245, 86, 136, 0.1);
          border-radius: 10px;
        }

        button.actived {
          opacity: 1;
        }

        >:nth-child(3) {
          font-size: 14px;
          line-height: 100%;
          color: #666666;
          text-align: center;

          span {
            color: #F95E93;
          }
        }

        
        .q1 {
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            color: #F84279;
          }

          .q2 {
            font-size: 30px;
            line-height: 34px;
            text-align: center;
            color: #F84279;
            margin-bottom: 15px;
          }

          .q3 {
            width: 583px;
            height: 64px;
            border: 1px solid #F84E84;
            border-radius: 10px;
            font-size: 20px;
            line-height: 15px;
            display: flex;
    justify-content: center;
    align-items: center;
            color: #F84279;
            cursor: pointer;
          }
      }

      .right {
        width: 404px;
        height: 516px;
        padding: 49px 41px 38px 44px;

        background: rgba(255, 255, 255, 0.7);
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(30px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 20px;

        >:nth-child(1) {
          font-size: 20px;
          line-height: 100%;
          color: #00C7DB;
          margin-bottom: 32px;
        }

        >:nth-child(2) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 34px;

          width: 314px;
          height: 56px;

          border: 1px solid #FF5285;
          border-radius: 10px;

          font-weight: 500;
          font-size: 20px;
          line-height: 100%;
          /* identical to box height, or 20px */

          display: flex;
          align-items: center;
          text-align: center;

          color: #FF5285;
          margin-bottom: 24px;
          cursor: pointer;
        }

        >:nth-child(3) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 34px;

          width: 316px;
          height: 56px;

          border: 1px solid #485573;
          border-radius: 10px;

          font-weight: 500;
          font-size: 20px;
          line-height: 100%;
          /* identical to box height, or 20px */

          display: flex;
          align-items: center;
          text-align: center;

          color: #485573;
          margin-bottom: 24px;
          cursor: pointer;
        }

        >:nth-child(4) {
          height: 0;
          border: 1px solid rgba(159, 184, 197, 0.5);
          margin-bottom: 29px;
        }

        >:nth-child(5) {
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          display: flex;
          align-items: center;

          color: #485573;
          margin-bottom: 16px;
        }

        >:nth-child(6) {
          font-size: 14px;
          line-height: 150%;
          color: #666666;
          margin-bottom: 25px;
        }

        >:nth-child(7) {
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          /* identical to box height, or 16px */

          display: flex;
          align-items: center;

          color: #485573;
          margin-bottom: 16px;
        }

        >:nth-child(8) {
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;

          color: #666666;
        }
      }
    }

  }
}
</style>