<template>
  <div class="Teamwork">
    <!-- 头部导航 -->
    <Topbar />
    <!-- 中间内容部分 -->
    <div class="content" style="height: calc(100vh - 432px);">
      <v-img :src='require("@/assets/imgs/BC.png")' style= "width: 769px;height: auto;position: absolute;top: 100px;"></v-img>
      <v-img :src='require("@/assets/imgs/DataAnalyse.png")' style=" width:740px;
    position: absolute;
    right: -50px;
    top: 7px;"></v-img>
      
      <div class="container" style="position:absolute ;top:300px;">
        <div class="left-text">
          <!--商务合作  -->
          <!-- <div class="mb-5">
            <h3>{{ $t("商務合作") }}</h3>
            <p>{{ $t("請複製並添加商務合作Telegram帳號") }}</p>
            <div class="item-list">
              <div class="left">
                <div class="logo"><img :src="brandImg1" alt="" /></div>
                <div class="brand">
                  <h3 class="ma-0">Telegram</h3>
                  <p>{{ name }}</p>
                </div>
              </div>
              <div class="right">
                <div
                  class="copy"
                  :data-clipboard-text="name"
                  @click="copyText()"
                >
                  {{ $t("複製") }}
                </div>
              </div>
            </div>
          </div> -->
          <!--客服邮箱  -->
          <div>
            <h3>{{ $t("商務合作") }}</h3>
            <!-- <p>{{ $t("請複製並添加商務合作Telegram帳號") }}</p> -->
            <div class="item-list">
              <div class="left">
                <div class="logo"><img :src="serviceImg" alt="" /></div>
                <div class="brand">
                  <h3 class="ma-0">{{ $t("Telegram") }}</h3>
                  <p>{{ name1 }}</p>
                </div>
              </div>
              <div class="right">
                <div
                  class="copy"
                  :data-clipboard-text="name1"
                  @click="copyText()"
                >
                  {{ $t("複製") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部栏 -->
    <Botbar />
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Botbar from "@/components/Botbar";
import i18n from "@/i18n/index";
import Clipboard from "clipboard";
export default {
  components: {
    Topbar,
    Botbar,
  },
  data() {
    return {
      name: "",
      name1: "",
      brandImg1: require("@/assets/imgs/brand-img1.png"),
      serviceImg: require("@/assets/imgs/tel.png"),
    };
  },
  created() {
    // 获取商务合作邮箱
    this.getTeamworkEmail();
  },
  methods: {
    // 获取商务合作邮箱
    getTeamworkEmail() {
      this.$get("/api/Home/WebSettings").then((res) => {
        this.name = res.data.telegram;
        this.name1 = res.data.customerEmail;
      });
    },

    // removeRecommend() {
    //   this.$router.push("/");
    // },
    // removeNewgame() {
    //   this.$router.push("/");
    // },
    // 复制邮箱
    copyText() {
      var clipboard = new Clipboard(".copy");
      clipboard.on("success", (e) => {
        this.$toast(i18n.t("複製成功"));
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Teamwork {
  background: #F0F6F9;
  .content {
    position: relative;
    width: 1124px;
    margin: 60px  auto 190px auto;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-text {
        width: 30%;
        .item-list {
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            display: flex;
            align-items: center;
            .logo {
              padding: 3px;
              border-radius: 50%;
              background-color: #dee7f4;
              margin-right: 5px;
              img {
                display: block;
                width: 40px;
              }
            }
            .brand {
              h3 {
                color: #333333;
                font-size: 18px;
                text-align: left;
              }
              p {
                margin: 0;
                color: #666666;
                font-size: 12px;
                text-align: left;
              }
            }
          }
          .right {
            display: flex;
            justify-content: flex-end;
            .copy {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 1.4rem;
              height: 33px;
              background: #00C7DB;
              border-radius: 35px;
              font-size: 18px;
              color: #ffffff;
            }
          }
        }
        h3 {
          font-weight: 700;
          font-size: 28px;
          color: #333333;
          text-align: left;
        }
        p {
          font-size: 12px;
          color: #666666;
          text-align: left;
          margin: 0;
        }
      }
      .right-img {
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
</style>