<template>
  <div style="position: fixed; left: 0; right: 0; top: 0; z-index: 100;background: #F0F6F9;">
    <div class="topbar">
      <div class="left">
        <div class="logo-Img" @click="$router.push('/')">
          <img :src="logoImg" alt="" />
        </div>
        <div class="routerLink">
          <span @click="$router.push('/')">{{ $t("站長推薦") }}</span>
          <span @click="orderGame">{{ $t("預約新游") }}</span>
          <span @click="$router.push('Teamwork')"> {{ $t("商務合作") }}</span>
          <!-- 
            <span @click="$router.push(skip4)">{{ $t("活動中心") }}</span>
          <span @click="removeNewgame()"> {{ $t("即將推出") }}</span>
          <span @click="$router.push(skip3)" v-if="userToken">
            {{ $t("常見問題") }}</span>-->
          <span @click="$router.push('UserInfo')">{{ $t("會員中心") }}</span>
        </div>
      </div>
      <div class="right" @mouseleave="mouseleave()">
        <div v-if="userToken" style="cursor: pointer; margin-right: 16px" @mouseover="mouseover()">
          <img @click="$router.push('/UserInfo')" :src="avatarImg" alt="" />
          <!-- 退出登录 -->
          <div class="logOut" v-if="logoutShow" @click="logOut()">
            {{ $t("退出登錄") }}
          </div>
        </div>
        <div style="display: flex; align-items: center;cursor: pointer;" v-else>
          <img @click="$router.push('/login')" :src="avatarImg" alt="" />
          <v-btn v-if="false" color="#fff" @click="$router.push('/login')">{{
            $t("登錄")
          }}</v-btn>
        </div>
        <ChangeLanguage />
      </div>
    </div>
  </div>
</template>

<script>
// 引入i18n
import i18n from "@/i18n/index";
// 切换语言
import ChangeLanguage from "@/components/ChangeLanguage";
export default {
  inject: ["reload"],
  components: {
    ChangeLanguage,
  },
  data() {
    return {
      // 退出登录显示
      logoutShow: false,
      logoImg: require("@/assets/imgs/logo-1.png"),
      //avatarImg: require("@/assets/imgs/image1.jpg"),
      avatarImg: require("@/assets/imgs/info.png"),
      userToken:localStorage.getItem("token")
    };
  },
  created() { 
   
  },
  methods: {
    // 退出登录
    logOut() {
      this.$toast(i18n.t("已退出登錄"));
      localStorage.removeItem("token");
      this.reload();
      this.userToken = "";
    },
    mouseover() {
      this.logoutShow = true;
    },
    mouseleave() {
      this.logoutShow = false;
    },
    orderGame(){ 
      if(this.$route.path=="/UserInfo")
          this.$emit("order");
        else
      this.$router.push({name:'UserInfo',query:{index:6}})
    },
    // 点击站长推荐 滑动到站长推荐
     removeRecommend() {
      this.$router.push({name:'Home'});
      if(this.$route.path=="/")
          this.$emit("removeRecommend");
      else this.$router.push({name:'Home',params:{href:'content2'}})
    },
    // 点击最新上架 滑动到最新上架
     removeNewgame() {
      this.$router.push({name:'Book'});
      if(this.$route.path=="/")
        this.$emit("removeNewgame");
      else  this.$router.push({name:'Home',params:{href:'content3'}})
       
     },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  height: 80px;
  line-height: 80px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 1124px;
  margin: 0 auto;

  .left {
    display: flex;
    align-items: center;
    height: 100%;

    .logo-Img {
      cursor: pointer;
      margin-right: 170px;

      img {
        display: block;
        height: 45.01182174682617px;
        width: 90px;
        left: 0px;
        top: 13.3018798828125px;
        border-radius: 0px;
      }
    }

    .routerLink {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      gap: 60px;

      span {
        font-family: "PingFang SC";
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
        cursor: pointer;

      }
    }
  }

  .right {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      display: block;
      height: 34.567901611328125px;
      width: 35px;
      left: 1022px;
    }

    .logOut {
      top: 60px;
      height: 58px;
      line-height: 50px;
      padding: 5px 15px;
      position: absolute;
      color: #999999;
      background-color: #fff;
      border-radius: 15px;
    }

    span {
      display: inline-block;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      text-align: center;

      cursor: pointer;
    }

    .v-btn {
      padding: 20px 36px;
      border-radius: 20px;
      color: #f84279;
      font-size: 18px;
      margin-left: 15px;
    }
  }
}
</style>