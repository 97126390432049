<template>
  <div class="error" v-if="modal">
    <div class="box">
      <h3>你滿18歲了嗎?</h3>
      <p>本網站可能涉及成人內容,未滿18歲謝絕進入</p>
      <v-checkbox
        v-model="checkbox"
        @change="changeCheckbox()"
        label="不在提醒"
      ></v-checkbox>
      <v-btn @click="closeModal()" height="50" block
        >是,我已年滿18歲並同意</v-btn
      >
      <div class="no" @click="openblank()">不,我還未滿18歲</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
      modal: null,
      checkbox: null,
    };
  },

  created() {
    if(window.location.href.indexOf('#/wxQrcode')>-1 || window.location.href.indexOf('#/zfbQrcode')>-1)
      return
    if (JSON.parse(sessionStorage.getItem("checkbox"))) {
      return;
    }
    sessionStorage.setItem("modal", true);
    sessionStorage.setItem("checkbox", true);
    this.modal = JSON.parse(sessionStorage.getItem("modal"));
    this.checkbox = JSON.parse(sessionStorage.getItem("checkbox"));
    this.interval();
  },

  methods: {
    interval() {
      this.timer = setInterval(() => {
        this.modal = true;
      }, 10000);
    },
    changeCheckbox() {
      sessionStorage.setItem("checkbox", this.checkbox);
      console.log(this.checkbox);
    },
    closeModal() {
      clearInterval(this.timer);
      if (!this.checkbox) {
        this.interval();
      }
      if (this.checkbox) {
        clearInterval(this.timer);
      }
      this.modal = false;
    },
    openblank() {
      window.location.href = "about:blank";
    },
  },
  beforeDestroy() {
    //页面关闭前关闭定时器
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.error {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: linear-gradient(to right, #ff768a 0%, #ffccaf 100%);
  .box {
    padding: 30px;
    text-align: left;
    opacity: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: #fff;
    border-radius: 20px;
    h3 {
      font-size: 32px;
      margin-bottom: 20px;
    }
    .v-btn {
      background: linear-gradient(88.87deg, #ff5285 38.11%, #fc8db4 110.53%);
      border-radius: 20px;
      margin-bottom: 30px;
      color: #fff;
      font-weight: 600;
    }
    .no {
      color: #e5c26e;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>