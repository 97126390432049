import Vue from "vue";

Vue.prototype.$getQueryString = function (name) {
    var value;
    var url = window.location.href;
    var pairs = url.split("?");
    if (pairs.length < 2) return value;

    pairs = pairs[pairs.length - 1].split("&");

    if (pairs.length < 1) return value;
    pairs.forEach((element) => {
        var pair = element.split("=");
        var key = pair[0];

        if (key.toLowerCase() == name.toLowerCase()) {
            value = pair[1];
            return;
        }
    });
    return value;
}

