<template>
  <div class="HomeAndroid" id="HomeAndroid">
    <div>
      <!-- 头部导航 -->
      <Topbar></Topbar>
      <!-- 中间内容部分 -->
      <div class="content">
        <!-- 轮播图部分 -->
        <div class="content1" style="cursor: pointer;">
          <Swiper :bannerList="bannerList" @gotoGame="bannerClicked" />
          <div style="width: 310px;margin-left: 20px;display: flex;flex-direction: column;">
            <v-img class="rounded-xl" v-if="bannerList2[0]"  @click="bannerClicked(
              bannerList2[0].url,
              bannerList2[0].url_type,
              bannerList2[0].banner_type,
              parseInt(bannerList2[0].id)
            );" :src="BigImg(bannerList2[0].url_pc)" style="margin-bottom: 20px;">
            </v-img>
            <v-img class="rounded-xl" v-if="bannerList2[1]" @click="bannerClicked(
              bannerList2[1].url,
              bannerList2[1].url_type,
              bannerList2[1].banner_type,
              parseInt(bannerList2[1].id)
            );" :src="BigImg(bannerList2[1].url_pc)">
            </v-img>
          </div>
        </div>
        <!-- 站长推荐 -->
        <div class="content2" id="content2">
          <div class="recommend">
            <div style="width: 490px;height: 0px;left: 634px;top: 17px;opacity: 0.6;border: 1px solid #8FA3AB;"></div>
            {{ $t("站長推薦") }}
            <div style="width: 490px;height: 0px;left: 634px;top: 17px;opacity: 0.6;border: 1px solid #8FA3AB;"></div>
          </div>
          <p v-show="isloading">{{ $t("加載中") }}</p>
          <ul class="ma-0 pa-0 mt-14" v-if="recommendList">
            <li v-for="(item, index) in recommendList" :key="index" class="gameInfo rounded-xl"
              :style="item.hover ? 'background: linear-gradient(126.84deg, #EBEAFE 26.93%, #FCE5EC 88.01%);border: 3px solid #FF5285;box-shadow: 0px 8px 15px rgba(255, 82, 133, 0.2);' : ''"
              @mouseover="m(item)" @mouseleave="mouseleave(item)">
              <v-img v-if="item.hot" width="30" :src="require('@/assets/imgs/fire.png')" style="width: 30px;right: -10px;position: absolute;z-index: 2;top: -10px;"></v-img>
              <div style="width: 251px;margin: 8px auto;;" class="cover">
                <div
                  :style="`background-image:url(${BigImg(item.cover)});filter: brightness(${item.hover ? 1 : 0.8});background-position: center center;border-radius: 10px;height:${item.hover ? '186px' : '143px'} ;transition: background-size 1.5s;background-size: ${item.hover ? '150%' : '100%'};`">
                </div>
                <v-fade-transition mode="out-in">
                  <div v-if="item.hover" class="download" @click="toGameDetails(item)">{{ $t("下載") }}</div>
                </v-fade-transition>
                <div v-show="!item.hover" style="background: rgba(34, 12, 18, 0.7);bottom: 0;position: absolute;width: 100%;display: flex;height: 31px;padding: 0 8px;line-height: 31px;">
                  <div style="font-family:'Source Han Sans CN';font-style: normal;font-weight: 700;font-size: 18px;color: white;">{{ item.name }}</div>
                  <div  style="font-family:'Source Han Sans CN';font-style: normal;font-weight: 400;font-size: 14px;color: white;padding-left: 5px;line-height: 37px;">{{item.title}}</div>
                </div>
              </div>

              <div v-if="!item.hover" style="display: flex;justify-content: space-between;padding: 6px 12px;align-items: center;">
                <div><v-img style="box-shadow: 0px 6px 10px #D6A6B8;border-radius: 10px;" width="32"
                    :src="BigImg(item.icon)"></v-img></div>
                <div class="download">{{ $t("下載") }}</div>
              </div>
            </li>
          </ul>
          <p v-else>{{ $t("暫無數據") }}</p>
        </div>
        <!-- 预约新游 -->
        <div class="content2" id="content3">
          <div class="recommend">
            <div style="width: 490px;height: 0px;left: 634px;top: 17px;opacity: 0.6;border: 1px solid #8FA3AB;"></div>
            {{ $t("預約新游") }}
            <div style="width: 490px;height: 0px;left: 634px;top: 17px;opacity: 0.6;border: 1px solid #8FA3AB;"></div>
          </div>
          <p v-show="isloading">{{ $t("預約新游") }}</p>
          <ul class="ma-0 pa-0 mt-14" v-if="newsList">
            <li v-for="(item, index) in newsList" :key="index" class="gameInfo rounded-xl"
              :style="item.hover ? 'background: linear-gradient(126.84deg, #EBEAFE 26.93%, #FCE5EC 88.01%);border: 3px solid #FF5285;box-shadow: 0px 8px 15px rgba(255, 82, 133, 0.2);' : ''"
              @mouseover="m(item)" @mouseleave="mouseleave(item)">
              <div style="width: 251px;margin: 8px auto;;" class="cover">
                <div :style="`background-image:url(${BigImg(item.cover)});filter: brightness(${item.hover ? 1 : 0.8});background-position: center center;border-radius: 10px;height:${item.hover ? '186px' : '143px'} ;transition: background-size 1.5s;background-size: ${item.hover ? '150%' : '100%'};`">
                </div>
                <v-fade-transition mode="out-in">
                  <div v-if="item.hover" class="download" :style="`${item.booked?'background:#00C7DB':''}`" @click="booking(item)">{{ item.booked? $t("已預約"): $t("預約") }}</div>
                </v-fade-transition>
                <div v-show="!item.hover" style="background: rgba(34, 12, 18, 0.7);bottom: 0;position: absolute;width: 100%;display: flex;height: 31px;padding: 0 8px;line-height: 31px;">
                  <div style="font-family: 'Source Han Sans CN';font-style: normal;font-weight: 700;font-size: 18px;color: white;">{{ item.name }}</div>
                  <div style="font-family: 'Source Han Sans CN';font-style: normal;font-weight: 400;font-size: 14px;color: white;padding-left: 5px;line-height: 37px;">{{ item.title }}</div>
                </div>
              </div>
              <v-fade-transition mode="out-in">
                <div v-if="!item.hover" style="display: flex;justify-content: space-between;padding: 6px 12px;align-items: center;">
                  <div><v-img style="box-shadow: 0px 6px 10px #D6A6B8;border-radius: 10px;" width="32"
                      :src="BigImg(item.icon)"></v-img></div>
                  <div class="download" :style="`${item.booked?'background:#00C7DB':''}`">{{ item.booked?$t("已預約"): $t("預約") }}</div>
                </div>
              </v-fade-transition>
            </li>
          </ul>
          <p v-else>{{ $t("暫無數據") }}</p>
        </div>
      </div>
      <!-- 浮动图片 -->
      <!-- <div class="folatImg" v-if="userToken" @click="getfloatImgClick()">
        <img src="../assets/imgs/folat-img.png" alt="" />
      </div> -->
      <!-- 底部栏 -->
      <Botbar />

      <Dialog ref="download" />
    </div>
  </div>
</template>
<script>
import Topbar from "@/components/Topbar";
import Botbar from "@/components/Botbar";
// 引入i18n
import i18n from "@/i18n/index";
import Swiper from "@/components/Swiper";
import SvgIcon from "@/components/Svgicon";
export default {
  components: {
    Topbar,
    Botbar,
    Swiper,
    SvgIcon,
  },
  data() {
    return {
      userToken: "",
      isloading: true,
      text: "none",
      // 轮播图片
      bannerList: [],
      bannerList2: [],
      // 站长推荐
      recommendList: [],
      // 最新上架
      newsList: [],
      href: this.$route.params.href,
    };
  },
  created() {
    this.userToken = localStorage.getItem("token");
    this.getHomeData();
  },
  mounted() {
    setTimeout(() => {
      if (this.href == "content2") this.removeRecommend()
      if (this.href == "content3") this.removeNewgame()
    }, 100);
  },
  methods: {
    booking(item){
      this.$get("/api/Home/bookingGame", {
          gameId:item.id,
          booked:!item.booked
        }).then((res) => {
            item.booked=!item.booked
         });
    },
    bannerClicked(url, url_type, banner_type, banner_id) {
      clearTimeout(this.timer);
      if (!url) return;
      if (url_type) {
        this.$post("/api/Home/G18ClickDown", {
          code: 3004,
          game_id: banner_id,
        }).then((res) => { });
        this.$router.push(url);
      } else {
        if (banner_type == 1) {
          this.$refs.download.dialog2 = true;
          this.$refs.download.text1 = "温馨提示";
          this.$refs.download.text2 = "請下載該遊戲、於遊戲內兌換！";
          this.$post("/api/Home/G18ClickDown", {
            code: 3004,
            game_id: banner_id,
          }).then((res) => { });
          return;
        }
        window.open(url); // 获取点击浏览记录
        this.$post("/api/Home/G18ClickDown", {
          code: 3004,
          game_id: banner_id,
        }).then((res) => { });
      }
    },
    getfloatImgClick() {
      this.$router.push("/Recharge");
      // 获取点击浏览记录
      this.getViewCount(3005);
    },
    // 点击站长推荐 滑动到站长推荐
    removeRecommend() {
      this.$nextTick(() => {
        var content2 = document.querySelector("#content2");
        var HomeAndroid = document.querySelector("#HomeAndroid");
        var scrollY = content2.offsetTop;
        HomeAndroid.scrollTo({
          top: scrollY - 80,
          behavior: "smooth",
        });
      });
    },
    // 点击最新游戏 滑动到最新游戏
    removeNewgame() {
      this.$nextTick(() => {
        var content3 = document.querySelector("#content3");
        var HomeAndroid = document.querySelector("#HomeAndroid");
        var scrollY = content3.offsetTop;
        HomeAndroid.scrollTo({
          top: scrollY - 80,
          behavior: "smooth",
        });
      });
    },
    // 获取首页数据
    getHomeData() {
      this.$get("/api/Home/Data").then((res) => {
        this.bannerList = res.data.banners;
        this.bannerList2 = res.data.banners2;
        this.recommendList = res.data.recommends;
        this.newsList = res.data.news;
        //this.recommendList=null;
        //this.newsList=null;
        this.isloading = false;
      });
    },
    m(item) {
      item.hover = true
      item.name = item.name + " "
      item.name = item.name.substring(0, item.name.length)
    },
    mouseleave(item) {
      item.hover = false
      item.name = item.name + " "
      item.name = item.name.substring(0, item.name.length)
    },
    // 跳转登录页
    logIn(id) {
      if (id == 3) {
        this.$router.push("/Teamwork");
      }
      if (id == 4) {
        if (this.userToken) {
          this.$router.push("/UserInfo");
        } else {
          this.$router.push("/login");
        }
      }
    },
    gotoGame(pc_landing_page, type) {
      if (!pc_landing_page) return;
      if (type) {
        this.$router.push(pc_landing_page);
      } else {
        this.getViewCount(3004);
        window.open(pc_landing_page);
      }
    },
    BigImg(item) {
      if(!item) return item
      if (item.indexOf("-big") > 0) {
        this.bigimg = item;
      } else {
        var hz = item.slice(item.lastIndexOf("."));
        var img = item.substring(0, item.lastIndexOf("."));
        this.bigimg = img + "-big" + hz;
      }
      return this.bigimg;
    },
    toGameDetails(game) {
      this.$router.push({ path: "/GameDetails", query: { id: game.id } });
    },
    // 跳转网址
    downloadGame(item) {
      this.getViewCount(3001, item.id);
      window.open(`${item.pc_landing_page}?${item.id}`);
      // if (isAndroid) {
      //   window.open(item.download_android);
      //   return;
      // }
      // if (isIOS) {
      //   window.open(item.download_ios);
      //   return;
      // }
      // window.open(item.download_windows);
    },
    // 获取点击浏览记录
    getViewCount(code, id) {
      this.$post("/api/Home/G18ClickDown", {
        code: code,
        game_id: id,
      }).then((res) => { });
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.HomeAndroid {
  margin-top: 60px;
  overflow: auto;
  background: #F0F6F9;

  .folatImg {
    cursor: pointer;
    position: fixed;
    right: 60px;
    bottom: 80px;

    img {
      display: block;
    }
  }

  .content {
    width: 1124px;
    margin: 80px auto 190px auto;
    ;

    .content1 {
      display: flex;
    }

    .content2 {
      .title {
        height: 34px;
      }

      .recommend {
        display: flex;
        align-items: center;
        gap: 28px;
        padding: 10px 0;
        font-weight: 700;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 31px;
        text-align: center;
        letter-spacing: -0.28px;
        color: #000000;
        margin-top: 50px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        li {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 266px;
          height: 207px;
          background: linear-gradient(126.84deg, #EBEAFE 26.93%, #FCE5EC 88.01%);
          border: 1px solid #FFFFFF;
          border-radius: 20px;

          .game-Icon {
            .v-image {
              cursor: pointer;
              width: 133px;
              height: 133px;
            }
          }

          .game-Name {
            padding: 15px 0 0 0;
            margin-left: 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: space-between;
            justify-content: flex-end;

            .gameName {
              cursor: pointer;
            }

            .gameRemark {
              color: #999999;
              cursor: pointer;
              padding: 5px 10px;
              border: 1px solid #999999;
              border-radius: 25px;
            }
          }

          .cover {
            position: relative;

          }

          .cover {

            .download {
              display: block;
              cursor: pointer;
              background: rgba(255, 82, 133);
              border-radius: 30px;
              color: white;
              margin: 0 auto;
              width: 208px;
              height: 44px;
              font-size: 16px;
              line-height: 44px;
              position: absolute;
              top: 74px;
              left: 21.5px;
            }
          }

          .download {
            cursor: pointer;
            height: 28px;
            background: rgba(255, 82, 133, 0.8);
            border-radius: 30px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 .8rem;
          }

          .explain {
            width: 230px;
            line-height: 1.2;
            position: absolute;
            top: 150px;
            word-break: break-all;
            color: #999999;
            left: 0;
            right: 0;
            text-align: left;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    .content3 {
      min-width: 800px;
      max-width: 1200px;
      margin: 0 auto;

      p {
        font-size: 14px;
      }

      .news {
        font-size: 24px;
        font-weight: 700;
        text-align: left;
        color: #333333;
        padding: 10px 0;
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 25%;
          display: flex;
          align-items: center;
          position: relative;

          .game-Icon {
            .v-image {
              cursor: pointer;
              width: 133px;
              height: 133px;
            }
          }

          .game-Name {
            padding: 15px 0 0 0;
            margin-left: 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 50px;

            // margin-bottom: 100px;
            .gameName {
              cursor: pointer;
            }

            .gameRemark {
              color: #999999;
              cursor: pointer;
              padding: 5px 10px;
              border: 1px solid #999999;
              border-radius: 25px;
            }

            .download {
              cursor: pointer;
              margin-top: 20px;
              height: 30px;
              background: linear-gradient(91.47deg,
                  #ff5b8c -39.27%,
                  #fd84ad 120.35%);
              line-height: 30px;
              border-radius: 35px;
              font-size: 14px;
              color: #fff;
              min-width: 88px;
              text-align: center;
              //  margin-top: 0;
              margin-top: 20px;
            }
          }

          .explain {
            width: 230px;
            line-height: 1.2;
            position: absolute;
            top: 150px;
            word-break: break-all;
            color: #999999;
            left: 0;
            right: 0;
            text-align: left;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

  }
}
</style>
