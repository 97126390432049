import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import { Scroll } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  directives: {
    Scroll,
  },
});

const opts = {};

export default new Vuetify(opts);
