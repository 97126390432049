<template>
  <v-dialog v-model="dialog2" width="809" height="535"  persistent class="elevation-0" >
    <div @click="dialog2=false" style="text-align: right;background: transparent;cursor: pointer;"><img :src='require("@/assets/imgs/close.png")' class="close"
        alt=""></div>
    <div class="main">
      <div class="left">
        <div>{{ $t("帳號憑證") }}</div>
        <div>{{ $t("請截圖保存您的帳號憑證") }}</div>
        <div>{{ $t("為什麽要保存帳號憑證") }}？</div>
        <div>{{
          $t(
            "如果帳號丟失,可在忘記密碼-帳號憑證找回上傳憑證或掃描憑證找回"
          )
        }}</div>
        <div @click="fastLogin"> {{ $t("登錄帳號") }}</div>
      </div>
      <div class="right">
        <img class="img1" :src='require("@/assets/imgs/tl.png")' alt="" style="top:0;left: 0;">
        <img class="img1" :src='require("@/assets/imgs/tr.png")' alt="" style="top:0;right:0">
        <img class="img1" :src='require("@/assets/imgs/br.png")' alt="" style="bottom: 0; right:0">
        <img class="img1" :src='require("@/assets/imgs/bl.png")' alt="" style="bottom: 0; left:0;">
        <vue-qr v-if="text" :text="text" margin="23" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      text: "",
      dialog2: false,
    };
  },
  methods: {
    closeDialog(skipOrder) {
      this.dialog2 = false;
      this.$router.push();
    },
    fastLogin() {
      this.$emit("fastLogin")
    }
  },
};
</script>

<style lang="scss" scoped>
.close {
  width: 28px;
  margin-bottom: 51px;
}

.main {
  width: 750px !important;
  height: 387px;
  background: #F0F6F9;
  box-shadow: 0px 25px 35px rgba(89, 100, 104, 0.2);
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  padding-top: 56px;

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 326px;

    >:nth-child(1) {
      font-size: 18px;
      line-height: 20px;

      color: #F84E84;
      margin-bottom: 8px;
    }

    >:nth-child(2) {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #F84E84;
      margin-bottom: 37px;
    }

    >:nth-child(3) {
      font-size: 18px;
      line-height: 30px;
      color: #485573;
    }

    >:nth-child(4) {
      font-size: 14px;
      line-height: 30px;
      color: #485573;
    }

    >:nth-child(5) {
      width: 316px;
      height: 60px;

      border: 1px solid #F8487E;
      border-radius: 15px;
      font-size: 20px;
      line-height: 15px;

      text-align: center;

      /* 主 */
      cursor: pointer;
      color: #F84279;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 37px;
    }
  }

  .right {
    position: relative;
    width: 250px;
    padding: 23px;
    height: 250px;

    >:nth-child(5){
      box-shadow: 0px 8.74636px 17.4927px rgba(0, 0, 0, 0.1);
border-radius: 8.74636px;
    }

    .img1 {
      position: absolute;
      width: 23px;
      height: 23px;
    }
  }
}
</style>