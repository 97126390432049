<template>
  <div class="chat" id="Chat">
    <!-- 聊天内容 -->
    <div class="chat-content">
      <div class="content" id="chat_box" style="height:425px">
        <div v-if="historyMessage">
          <div v-for="(item, index) in historyMessage.list" :key="index">
            <!-- 其他人的消息 -->
            <div v-if="item.msgType == 1">
              <div class="other-msg">
                <p>{{ item.createTime }}</p>
                <div class="avatar">
                  <img :src='require("@/assets/imgs/chat_left_icon.png")' alt="" />
                  <span>{{ item.message }}</span>
                </div>
              </div>
            </div>

            <!-- 自己的消息 -->
            <div v-if="item.msgType == 0">
              <div class="self-msg">
                <p>{{ item.createTime }}</p>
                <div class="avatar">
                  <img :src='require("@/assets/imgs/chat_right_icon.png")' alt="" />
                  <span>{{ item.message }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="botbar">
      <!-- 待处理和处理中 -->
      <div class="unfinish" >
        <h3>{{ $t("上傳憑證(最多5張截圖)") }}</h3>
        <div class="addImg-content">
          <div class="addImg">
            <!-- 已经上传过的凭证 -->
            <div class="smallImg" v-for="(item, index) in UploadImage" :key="index" @click.stop="showBigImg(item)">
              <img :src="item" alt="" />
            </div>
            <!-- 还未上传的凭证 -->
            <div class="smallImg" v-if="status!=1" v-for="(item, index) in unUploadImage" :key="item.id" @click.stop="showBigImg(item)">
              <img :src="item" alt="" />
              <v-icon @click="deleteImage(index)" size="20">mdi-close</v-icon>
            </div>

            <div class="upload" v-if="status!=1">
              <!-- 已上传加上未上传图片不超过五张 -->
              <v-file-input v-if="unUploadImage.length + UploadImage.length < 5" v-model="imageFile" hide-details=""
                background-color="#fff" type="file" class="img-upload-input" solo id="img-upload-input"
                accept="image/jpg, image/jpeg, image/png, image/gif" prepend-icon="mdi-plus"
                @change="uploadImage()"></v-file-input>
            </div>
          </div>
        </div>

        <div class="input-box" v-if="status!=1">
          <div class="input">
            <v-text-field v-model="inputFile" hide-details="" autofocus :label="$t('輸入聊天室資訊')" rounded
              background-color="#ECECEC" solo @keyup.enter.native="sendMessage()"></v-text-field>
          </div>
          <div class="send" @click="sendMessage()">
            <img :src='require("@/assets/imgs/chat_send.png")' alt="">
          </div>
        </div>
      </div>
      <!-- 已处理 -->
      <div class="finish" v-if="status == 12">
        <div class="addImg-content">
          <div class="addImg">
            <div class="smallImg" v-for="(item, index) in UploadImage" :key="index">
              <img :src="item" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 查看大圖 -->
      <v-dialog v-model="dialog" max-width="360" max-height="700">
        <v-img contain class="elevation-7 rounded-lg" :src="bigImg" :lazy-src="smallImg"><template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/index";
export default {
  props: ["status", "titleId", "typeId"],
  data() {
    return {
      dialog: false,
      bigImg: null,
      smallImg: null,
      timer: null,
      // 输入框绑定的值
      inputFile: "",
      imageFile: null,
      typeName: "",
      avatar: require("@/assets/imgs/Frame.png"),
      questionType: [],
      // 已经上传过的凭证照片
      UploadImage: [],
      // 展示上传图片
      unUploadImage: [],
      // 历史信息
      historyMessage: null,
    };
  },

  created() {
    this.getQuestionType();
    this.getHistoryChatMessage();
    if (this.status == 0 || this.status == 2) {
      // 实现轮询
      this.timer = window.setInterval(() => {
        setTimeout(this.getNewChatmessage(), 0);
      }, 3000);
    }
  },
  methods: {
    deleteImage(index) {
      this.unUploadImage.splice(index, 1);
    },
    // 提交信息
    sendMessage() {
      if (!this.inputFile) {
        this.$toast(i18n.t("請說明您的問題"));
        return;
      }

      this.$post("/Chat/App_ChatSave", {
        titleId: this.titleId,
        message: this.inputFile,
        files: this.unUploadImage,
      }).then((res) => {
        if (res.code == 0) {
          this.historyMessage.list.push({
            msgType: 0,
            createTime: res.data,
            message: this.inputFile,
          });
          setTimeout(function () {
            if (document.querySelector("#chat_box"))
              document.querySelector("#chat_box").scrollTop =
                document.querySelector("#chat_box").scrollHeight;
          }, 0);
          if (this.unUploadImage.length) {
            this.UploadImage.push(this.unUploadImage);
            this.unUploadImage = [];
          }
          this.inputFile = "";
          this.unUploadImage = [];

          this.$toast(i18n.t("資訊上傳成功"));
        }
      });
    },
    // 上传图片
    uploadImage() {
      if (this.imageFile == null) {
        return;
      }
      var formData = new window.FormData();
      formData.append("file", this.imageFile);
      this.$post("/Chat/App_Uploadfile", formData).then((res) => {
        if (res.code == 0) {
          if (this.unUploadImage.length + this.UploadImage.length <= 4) {
            this.unUploadImage.push(res.data.fileName);
          } else {
            this.$toast(i18n.t("最多五張圖片"));
          }
        }
        console.log(res.data);
      });
    },
    // 问题类型
    getQuestionType() {
      this.$get("/Chat/GetQuestionType").then((res) => {
        this.questionType = res.data;
        this.questionType.forEach((item) => {
          if (item.value == this.titleId) {
            this.typeName = item.text;
          }
        });
      });
    },
    // 获取历史消息
    getHistoryChatMessage() {
      this.$get("/Chat/App_Chat", {
        titleId: this.titleId,
        typeId: this.typeId,
      }).then((res) => {
        this.historyMessage = res.data;
        this.UploadImage = res.data.images;
      });
    },
    // 获取最新的消息
    getNewChatmessage() {
      this.$get("/Chat/App_GetNewChat", {
        titleId: this.titleId,
      }).then((res) => {
        if (res.code == 0 && res.message != "") {
          for (let index = 0; index < res.data.length; index++) {
            res.data[index].msgType = 1;
          }
          this.historyMessage.list = this.historyMessage.list.concat(res.data);
        }
      });
    },
    showBigImg(item) {
      if (item.indexOf("-big") > 0) {
        this.bigImg = item;
        this.smallImg = item;
      } else {
        var hz = item.slice(item.lastIndexOf("."));
        var img = item.substring(0, item.lastIndexOf("."));
        this.bigImg = img + "-big" + hz;
        this.smallImg = item;
      }
      this.dialog = true;
    },
  },

  destroyed() {
    //离开页面是销毁
    clearInterval(this.timer);
    this.timer = null;
  },

  watch: {
    historyMessage() {
      setTimeout(function () {
        if (document.querySelector("#chat_box")) "";
        document.querySelector("#chat_box").scrollTop =
          document.querySelector("#chat_box").scrollHeight;
      }, 0);
    },
    immediate: true, //立即进行监听
    deep: true,
  },
};
</script>

<style lang="scss" scoped>
.chat {
  background: #FFFFFF;
  border: 1px solid #9FB8C5;
  border-radius: 10px;
  margin-top: 38px;

  .topbar {
    background-color: #fff;
    padding: 0 24px;
    height: 44px;
    align-items: center;
    justify-content: space-between;

    .head-title {
      width: 58%;
      text-align: left;
      font-size: 18px;
      color: #000;
    }
  }

  .chat-content {
    padding: 30px 36px 0px 36px;
    height: 425px;

    .content {
      padding-bottom: 20px;
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;

      // 其他人消息
      .other-msg {
        margin: 10px 0;
        text-align: left;

        p {
          font-size: 14px;
          line-height: 20px;
          color: #8F8DA5;
          margin-bottom: 10px;
        }

        .avatar {
          display: flex;
          align-items: center;

          img {
            display: block;
            margin-right: 5px;
            width: 26px;
          }

          span {
            padding: 12px 29px 12px 10px;
            font-size: 12px;
            line-height: 17px;
            color: #FFFFFF;
            background: #00C7DB;
            border-radius: 20px 20px 20px 0px;
          }
        }
      }

      // 自己发送的消息
      .self-msg {
        margin-bottom: 41px !important;
        ;
        margin: 10px 0;
        text-align: right;

        p {
          font-size: 14px;
          line-height: 20px;
          color: #8F8DA5;
          margin-bottom: 10px;
        }

        .avatar {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;

          img {
            display: block;
            width: 26px;
            margin-left: 5px;
          }

          span {
            padding: 12px 33px 12px 27px;
            font-size: 12px;
            line-height: 17px;
            color: #FFFFFF;
            background: #F84279;
            border-radius: 20px 20px 0px 20px;
          }
        }
      }
    }
  }

  .botbar {
    border-top: 0.5px solid #9FB8C5;

    z-index: 50;

    // 未处理完成
    .unfinish {
    

      h3 {
        margin-top: 18px;
        margin-left: 37px;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 20px;

        color: #8F8DA5;
      }

      // 添加上传图片
      .addImg-content {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-left: 26px;
        margin-bottom: 18px;

        .addImg {
          display: inline-flex;
          align-items: center;
          padding: 10px;

          .smallImg {
            position: relative;
            width: 60px;
            height: 60px;
            border-radius: 5px;
            border: 1px solid #485573;
            margin-right: 10px;
            flex-shrink: 1;
            flex-grow: 0;

            .v-icon {
              position: absolute;
              right: -10px;
              top: -10px;
              background-color: #f00;
              border-radius: 50%;
              color: #fff;
            }
          }

          img {
            width: 60px;
            height: 60px;
            display: block;
            border-radius: 5px;
          }

          .upload::v-deep {
            position: relative;
            width: 60px;
            height: 60px;

            .v-input {
              height: 100%;
              width: 100%;

              .v-input__prepend-outer {
                margin: 0;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                z-index: 100;
                position: absolute;

                .v-icon {
                  width: 50px;
                  height: 50px;
                }
              }

              .v-input__control {
                width: 60px;
                height: 60px;
                flex-direction: unset;
                border: 1px solid #d2d2d2;

                .v-file-input__text {
                  display: none;
                }

                .v-input__append-inner {
                  display: none;
                }
              }
            }
          }
        }
      }

      .input-box::v-deep {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        background: #F1F1F1;
        border-radius: 0 0 10px 10px;

        font-size: 14px;
line-height: 20px;

color: #9FB8C5;

        .input {
          .v-input__slot {
            border-color: rgb(236, 236, 236) !important;
    background: rgb(255, 255, 255) !important;
            border-radius: 50px;
            width: 577px;
            box-shadow: none;

            .v-label {
              font-size: 14px;
              color: #999999;
            }
          }

          margin: 31px 20px 35px 37px;
        }

        .send{
          img{
          width: 56px;
          width: 56px;
          margin-right: 36px;
          }
        }
      }
    }

    .btn1 {

      height: 76px;

      background: linear-gradient(91.69deg, #F84077 16.39%, #F95E93 139.39%);
      box-shadow: 0px 15px 30px rgba(245, 86, 136, 0.1);
      border-radius: 10px;

      font-size: 18px;
      line-height: 20px;

      color: #FFFFFF;

    }
  }
}</style>