<template>
  <div class="index">
    <!-- 头部导航 -->
    <Topbar skip1="UserInfo" skip2="Teamwork" skip3="FAQ" skip4="ActivityCenter" />
    <!-- 中间内容部分 -->
    <div class="content">
      <div class="div1">
        <div class="div_logo">
          <v-img style="border-radius: 20px;"
            src="https://static.play819.xyz/resources/G18_Games/d87ed96b7b154248baf585c0442f0819-big.gif"></v-img>
        </div>
        <div class="div_name">
          <div>英雄傳說 創之軌跡</div>
          <div>THE LEGEND OF HEROES: HAJIMARI NO KISEKI</div>
          <div>
            <div>角色扮演</div>
            <div style="color:#9F66CB;border: 1px solid #9F66CB;">繁体中文</div>
            <div style="color:#EE4D08;border: 1px solid #EE4D08;">预约</div>
          </div>
        </div>
        <div class="div_download">
          <div class="ios"><img :src='require("@/assets/imgs/ios-1.png")' /> App Store 下载</div>
          <div class="ios"><img :src='require("@/assets/imgs/android-0.png")' />Android 下载</div>
          <!-- <div class="ios"><img :src='require("@/assets/imgs/pc-0.png")' />Window 下载</div> -->
        </div>
      </div>
      <div class="swiper">
        <div style="--swiper-navigation-color: #fff; --swiper-pagination-color: #fff" class="swiper1">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <video src="https://res-r.qingjiaota.com/video/1670912137983.mp4" width="100%" height="auto"
                controls></video>
              <!-- <img src="https://swiperjs.com/demos/images/nature-1.jpg" /> -->
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-8.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-9.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-10.jpg" />
            </div>
          </div>

          <div class="swiper-button-prev"><img :src='require("@/assets/imgs/left.png")' /></div>
          <div class="swiper-button-next"><img :src='require("@/assets/imgs/right.png")' /></div>
        </div>
        <div thumbsSlider="" class="swiper2">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-8.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-9.jpg" />
            </div>
            <div class="swiper-slide">
              <img src="https://swiperjs.com/demos/images/nature-10.jpg" />
            </div>
          </div>
        </div>
      </div>
      <div class="description">
        <div>游戏介绍</div>
        <div></div>
        <div>
          故事RPG“軌跡”系列誕生15周年的紀念之作，同時也是系列最新作品的《創之軌跡》登陸Steam！

          侵蝕帝國全土的災厄“巨碩黃昏”降臨──
          在英雄們的活躍下渡過危機的塞姆利亞大陸上，
          如今將編織出新的因果之鏈，而故事也將朝“終點”開始流轉。
          遊戲的舞臺就在災厄“巨碩黃昏”降臨後的塞姆利亞大陸。
          以在大陸各地活動的三位主角為主軸，形形色色的離奇命運將錯綜復雜地互相交會。

          其之壹位以“英雄”的身分、其中壹位則是作為“解放者”，其中壹位則是以“隱者”之姿采取行動──
          《英雄傳說
          創之軌跡》有著能夠任意切換三條路線，以不同視角描寫同壹時間軸上故事的“交錯故事”系統，以及深入刻劃包含新登場角色在內多達50人以上可遊玩角色的背景的“額外篇章”劇本等要素，搭載了能夠充分發揮“軌跡”系列優點的多種新系統。

          再次獨立就在眼前，如今克洛斯貝爾的人們內心有何想法？喪失了強大的支柱後，帝國人心中又梭巡著何種念頭？
          而在盟主的宣言下將“奧菲斯最終計劃”提升至新的階段後，結社《噬身之蛇》又有什麽樣的企圖……？

          Steam版中收錄了包含服裝和裝飾道具等在內的30種以上的額外內容！
          除了能裝備在各角色的頭、手臂、背上等處的各種裝飾性道具和原創服裝外，當然也包含大型額外內容“支援課的暑假”。
        </div>
        <div></div>
      </div>
      <div class="env">
        <div>游戏环境</div>
        <div>IOS</div>
        <div>10.0+</div>
        <div>Android</div>
        <div>6.0+</div>
      </div>
    </div>
    <!-- 底部栏 -->
    <Botbar />
  </div>
</template>





<script>
import Topbar from "@/components/Topbar";
import Botbar from "@/components/Botbar";
import i18n from "@/i18n/index";



export default {
  components: {
    Topbar,
    Botbar
  },
  data() {
    return {
      id:$route.query.id,
      gameInfo:null
    };
  },
  mounted() {
    
  },
  methods: {
    setThumbsSwiper(item) {
      this.thumbsSwiper = item
    }
  },
};
</script>

<style lang="scss" scoped>
.index {
  margin-top: 60px;
  background: #F0F6F9;

  .content {
    font-family: 'PingFang SC';
    font-style: normal;

    position: relative;
    width: 1124px;
    margin: 60px auto 190px auto;

    .div1 {
      display: flex;
      justify-content: space-between;
      gap: 30px;

      .div_logo {
        width: 218px;
        margin: 9px;
      }

      .div_name {
        width: 100%;
        text-align: left;
        position: relative;

        >:nth-child(1) {
          font-weight: 700;
          font-size: 24px;
          color: #485573;
        }

        >:nth-child(2) {
          font-weight: 400;
          font-size: 16px;
          color: #666666;
        }

        >:nth-child(3) {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          gap: 10px;
          position: absolute;
          bottom: 0;

          div {
            border: 1px solid #4DADD6;
            border-radius: 2px;
            color: #4DADD6;
            padding: 3px 15px;
          }

        }
      }


      .div_download {
        img {
          width: 22.45px;
        }

        font-size: 22px;
        color: #69789C;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        >:nth-child(1) {
          background: #05FFF0;
          color: black;
        }

        div {
          border: 1px solid #69789C;
          border-radius: 10px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 15px 16px;
          gap: 8px;

          width: 220px;
          height: 56px;
          border-radius: 10px;
          cursor: pointer;
        }
      }

    }


    .swiper {
      margin-top: 75px;

      .swiper1 {
        margin: 0 auto;
        width: 982px;

        .swiper-slide {
          height: 486px;
          border-radius: 10px;
        }
      }

      .swiper2 {
        box-sizing: border-box;
        margin-top: 26px;
        padding: 10px 0;

        .swiper-slide {
          filter: brightness(0.5);
          width: 213px;
          height: 93px;
        }

        .swiper-slide-thumb-active {
          filter: unset;
        }
      }

      .swiper1 .swiper-slide,
      .swiper2 .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;

        background-size: cover;
        background-position: center;
        border-radius: 10px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }

    swiper-slide.swiper-slide-prev,
    .swiper-slide.swiper-slide-next {
      filter: brightness(0.5);
    }

    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after {
      content: none;
    }


    .swiper-button-next:after,
    .swiper-rtl .swiper-button-prev:after {
      content: none;
    }

    .swiper-button-next {
      margin-right: -76px;
    }

    .swiper-button-prev {
      margin-left: -76px;
    }

    .swiper-button-next img,
    .swiper-button-prev img {
      width: 48px;
      height: auto;
    }

    .description {
      margin-top: 60px;

      >:nth-child(1) {
        margin-bottom: 2px;
        font-weight: 400;
        font-size: 22px;
        line-height: 31px;
        letter-spacing: -0.28px;
        color: #15ABBA;
        text-align: left;
      }

      >:nth-child(2),
      >:nth-child(4) {
        opacity: 0.6;
        border: 1px solid #9FB8C5;
      }

      >:nth-child(3) {
        margin: 34px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 40px;
        color: #666666;
        text-align: left;
      }

    }

    .env {
      margin-top: 45px;
      text-align: left;

      >:nth-child(1) {
        font-weight: 400;
        font-size: 16px;
        line-height: 33px;
        letter-spacing: -0.28px;
        color: #8F8DA5;
      }

      >:nth-child(2),
      >:nth-child(4) {
        font-weight: 400;
        font-size: 16px;
        line-height: 33px;

        letter-spacing: -0.28px;

        color: #05FFF0;
      }

      >:nth-child(3),
      >:nth-child(5) {
        font-size: 16px;
        line-height: 33px;
        letter-spacing: -0.28px;

        color: #485573;
      }
    }
  }

}
</style>