import { render, staticRenderFns } from "./zfbQRcode.vue?vue&type=template&id=1bd17140&scoped=true&"
import script from "./zfbQRcode.vue?vue&type=script&lang=js&"
export * from "./zfbQRcode.vue?vue&type=script&lang=js&"
import style0 from "./zfbQRcode.vue?vue&type=style&index=0&id=1bd17140&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd17140",
  null
  
)

export default component.exports