import axios from "axios"; // 引入axios
import Vue from "vue";

var vue = new Vue();


axios.defaults.timeout = 20000;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
var lang = localStorage.getItem("locale") || "zh-TW";
// 请求头
axios.defaults.headers = {
  "Content-Type": "application/json;",
  "accept-language": lang,
};

// 设置请求拦截
axios.interceptors.request.use(
  // 请求成功时
  function (config) {
    //if(config.apiType=="pay")
      //config.baseURL=process.env.VUE_APP_APIPay_BASE_URL
      
    // Do something before request is sent
    let token = localStorage.getItem("token");
    if (token)
      config.headers.authorization = token;
    return config;
  },
  // 请求失败时
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (!error.response) {
      vue.$toast("无法连接服务器");
      return null;
    } else if (error.response.status === 401) {
      //vue.$toast("登录失效");
      localStorage.removeItem("token");
      localStorage.removeItem("vuex");
      window.location = "/#/login";
      return;
    } else if (error.response.status === 403) {
      window.location = "/";
      vue.$toast("403");
      return;
    } else if (error.response.status === 404) {
      window.location = "/";
      vue.$toast("404");
      return;
    } else if (error.response.status === 500) {
      vue.$dialog.message.error({
        icon: "mdi-alert-circle-outline",
        text: error.response.data,
        title: "500",
        showClose: true,
        scrollable: true,
      });
      return;
    }
  }
);

export default {
  get(url, params, autoHandleError = false) {
    return new Promise((resovle, reject) => {
      axios
        .get(url, {
          params: params,
        })
        .then((res) => {
          if (res.data.code != 0) {
            if (autoHandleError) this.$toast(res.data.message);
            else resovle(res.data);
          } else {
            resovle(res.data);
          }
        })
        .catch((error) => {
          reject(error.data);
        });
    });
  },
  post(url, params) {
    return new Promise((resovle, reject) => {
      axios
        .post(url, params)
        .then((res) => {
          if (res.data.code != 0) {
            this.$toast(res.data.message);
            reject(res.data);
          } else {
            resovle(res.data);
          }
        })
        .catch((error) => {
          reject(error.data);
        });
    });
  },
  getpay(url, params, autoHandleError = false) {
    return new Promise((resovle, reject) => {
      axios
        .get(url, {
          params: params,
          apiType:"pay"
        })
        .then((res) => {
          if (res.data.code != 0) {
            if (autoHandleError) this.$toast(res.data.message);
            else resovle(res.data);
          } else {
            resovle(res.data);
          }
        })
        .catch((error) => {
          reject(error.data);
        });
    });
  },
  postpay(url, params) {
    return new Promise((resovle, reject) => {
      axios
        .post(url, params,{ apiType:"pay"})
        .then((res) => {
          if (res.data.code != 0) {
            this.$toast(res.data.message);
            reject(res.data);
          } else {
            resovle(res.data);
          }
        })
        .catch((error) => {
          reject(error.data);
        });
    });
  },
};
