<template>
  <transition name="fade">
    <div class="toast" v-show="show" v-text="message">
      <!-- 使用v-html，展示多样化样式内容 -->
      <!-- {{message}} --><!-- 若仅展示固定样式文本，使用此方式 -->
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message:"",
    };
  },
};
</script>

<style lang="scss" scoped>
.toast {
  position: fixed;
  left: 50%;
  bottom: 10%;
  margin-left: -100px;
  padding: 1vw;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  background-color: #ff5285;
  border-radius: 5px;
  z-index: 999;
}
.fade-enter-active,
.fade-leave-active {
  transition: 0.3s ease-out;
}
.fade-enter {
  opacity: 0;
  transform: scale(1.2);
}
.fade-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
</style>