<template>
  <div class="index">
    <!-- 头部导航 -->
    <Topbar />
    <!-- 中间内容部分 -->
    <div class="content" style="height: calc(100vh - 432px);">
      <div >
        <img style="width: 34px;height: 38px;cursor: pointer;" :src='require("@/assets/imgs/back.png")' @click="$router.push('/login')" alt="">
      </div>
      <div class="box">
        <div>{{ $t("密碼找回") }}</div>
        <div class="d-flex justify-space-between" @click="$router.push('/FindByAccountCode')">
          {{ $t("帳號憑證找回") }}
          <v-icon size="32" color="#848484">mdi-chevron-right</v-icon>
        </div>
        <v-divider></v-divider>
      </div>
    </div>
    <!-- 底部栏 -->
    <Botbar />
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Botbar from "@/components/Botbar";
import i18n from "@/i18n/index";
export default {
  components: {
    Topbar,
    Botbar,
  },
}
</script>

<style lang="scss" scoped>
.index {
  margin-top: 60px;
  background: #F0F6F9;

  .content {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;

    position: relative;
    width: 1124px;
    margin: 60px auto 190px auto;
    text-align: left;

    .box {
      width: 755px;
      height: 103px;
      margin: 0 auto;

      >:nth-child(1) {
        font-size: 20px;
        line-height: 100%;
        /* identical to box height, or 20px */

        display: flex;
        align-items: center;
        text-align: center;

        color: #00C7DB;
      }

      >:nth-child(2){
        cursor: pointer;
        margin-top: 50px;
      }
    }

  }
}
</style>
