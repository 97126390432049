import Vue from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

const requireComponent = require.context("@/components", true, /\.vue$/);

requireComponent.keys().forEach((fileName) => {
  var fn = fileName.split("/");
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fn[fn.length - 1].replace(/^\.\//, "").replace(/\.\w+$/, ""))
  );
  //console.log(componentName)
  Vue.component(componentName, componentConfig.default || componentConfig);
});
