<template>
  <div class="Botbar">
    <div class="botbar">
      <div>
        <v-img :src='require("@/assets/imgs/logo-1.png")' style="width: 140px;
        height: 70.89px;"></v-img>
      </div>
      <div>
        <p >
          {{ $t("本網站涉及成人內容,未滿18歲的人不得瀏覽,否則您將願意接受懲罰") }}
        </p>
        <p>
          <span class="sp1" @click="$router.push('/TOS')">
            {{ $t("服務條款") }}</span>
          <span class="sp3" @click="$router.push('/Privacy')">
            {{ $t("隱私權條款") }}</span>
          <span class="sp3" @click="$router.push('/Teamwork')">
            {{ $t("聯繫我們") }}</span>
          <span class="sp3" @click="$router.push('/Teamwork')">
            {{ $t("商務合作") }}</span>
        </p>
      </div>


    </div>
  </div>
</div></template>

<script>
// 引入i18n
import i18n from "@/i18n/index";
export default {};
</script>

<style lang="scss" scoped>
.Botbar {

  background-color: #fff;

  .botbar {
    z-index: 100;
    left: 0;
    right: 0;
    height: 161px;
    bottom: 0;
    width: 1124px;
    margin: 0 auto;
    padding-top: 27.94px;
    display: flex;

    p {
      text-align: left;
      color: #8F8DA5;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;

      .sp1,
      .sp3 {
        color: #69658B;
        margin-right:20px;
        cursor: pointer;
      }
    }
  }
}
</style>