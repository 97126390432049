<template>
  <div class="index">
    <!-- 头部导航 -->
    <Topbar />
    <!-- 中间内容部分 -->
    <div class="content" style="height: calc(100vh - 432px);">
      <div>
        <img style="width: 34px;height: 38px;cursor: pointer;" @click="back" :src='require("@/assets/imgs/back.png")' alt="">
      </div>
      <div class="box">
        <div class="left">
          <div> {{ $t("登錄") }}</div>
          <v-form class="my-5" ref="form">
            <v-text-field :placeholder="$t('請輸入用戶名')" color="info" outlined clearable v-model="moblie"
              :rules="[rules.required]" prepend-inner-icon="mdi-account" class="rounded-lg" autofocus></v-text-field>
            <v-text-field color="info" prepend-inner-icon="mdi-lock" :placeholder="$t('請輸入密碼')" outlined clearable
              :type="'password'" v-model="password" :rules="[rules.required]" class="rounded-lg"></v-text-field>
            <v-btn height="60" block class="rounded-lg mt-5 btn1" :class="moblie && password ? 'actived' : ''" bottom
              @click="validate">
              {{ $t("登錄帳號") }}
            </v-btn>
          </v-form>
          <div>{{$t("登入，表示同意本站")}}<span @click="$router.push('Privacy')"> {{$t("隱私權條款")}}</span></div>
        </div>
        <div class="right">
          <div> {{ $t("還沒有帳號") }}</div>
          <div @click="$router.push('/signup')"> {{ $t("註冊帳號（免費）") }}</div>
          <div @click="$router.push('/forgetpassword')"> {{ $t("忘記密碼") }}</div>
          <div></div>
          <div> {{ $t("郵件信息，密碼輸入不正確的情形") }}</div>
          <div> {{ $t("請確認英文大小寫數字是否正確，並注意標點與符號的狀態，再次正確的輸入資料") }}</div>
          <div> {{ $t("如何註冊會員呢") }}</div>
          <div> {{ $t("尚未註冊會員請用【註冊帳號】來創建帳號") }}</div>
        </div>
      </div>
    </div>
    <!-- 底部栏 -->
    <Botbar />
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Botbar from "@/components/Botbar";
import i18n from "@/i18n/index";
export default {
  components: {
    Topbar,
    Botbar,
  },
  data() {
    return {
      alert: true,
      moblie: null,
      password: null,
      token: null,
      rules: {
        required: (value) => !!value || i18n.t("必填項"),
      },
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    if (this.token) {
      this.$router.push("/UserInfo");
    }
  },
  methods: {
    back(){
      history.back();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$post("/api/Account/Login", {
          account: this.moblie,
          password: this.password,
          gameId: process.env.VUE_APP_API_BASE_GameId,
        }).then((res) => {

          this.$toast(i18n.t("登陸成功"));
          localStorage.setItem("token", res.data.token);
          this.$router.push("/");
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  margin-top: 60px;
  background: #F0F6F9;

  .content {
    font-family: 'PingFang SC';
    font-style: normal;

    position: relative;
    width: 1124px;
    margin: 60px auto 190px auto;
    text-align: left;

    .box {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;

      .left {
        width: 700px;
        height: 516px;
        padding: 49px 59px 51px 58px;

        background: rgba(255, 255, 255, 0.7);
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(30px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 20px;

        >:nth-child(1) {
          font-size: 20px;
          line-height: 100%;
          color: #00C7DB;
          margin-bottom: 32px;
        }

        >:nth-child(2) {
          font-size: 20px;
          line-height: 100%;
          color: #00C7DB;
          margin-bottom: 32px;
        }


        button {
          margin-bottom: 47px;
          font-size: 20px;
          line-height: 20px;
          color: #FFFFFF;
          background: linear-gradient(91.69deg, #F84077 16.39%, #F95E93 139.39%);
          opacity: 0.5;
          box-shadow: 0px 15px 30px rgba(245, 86, 136, 0.1);
          border-radius: 10px;
        }

        button.actived {
          opacity: 1;
        }

        >:nth-child(3) {
          font-size: 14px;
          line-height: 100%;
          color: #666666;
          text-align: center;

          span {
            color: #F95E93;
          }
        }
      }

      .right {
        width: 404px;
        height: 516px;
        padding: 49px 41px 38px 44px;

        background: rgba(255, 255, 255, 0.7);
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(30px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 20px;

        >:nth-child(1) {
          font-size: 20px;
          line-height: 100%;
          color: #00C7DB;
          margin-bottom: 32px;
        }

        >:nth-child(2) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 34px;

          width: 314px;
          height: 56px;

          border: 1px solid #FF5285;
          border-radius: 10px;

          font-weight: 500;
          font-size: 20px;
          line-height: 100%;
          /* identical to box height, or 20px */

          display: flex;
          align-items: center;
          text-align: center;

          color: #FF5285;
          margin-bottom: 24px;
          cursor: pointer;
        }

        >:nth-child(3) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 34px;

          width: 316px;
          height: 56px;

          border: 1px solid #485573;
          border-radius: 10px;

          font-weight: 500;
          font-size: 20px;
          line-height: 100%;
          /* identical to box height, or 20px */

          display: flex;
          align-items: center;
          text-align: center;

          color: #485573;
          margin-bottom: 24px;
          cursor: pointer;
        }

        >:nth-child(4) {
          height: 0;
          border: 1px solid rgba(159, 184, 197, 0.5);
          margin-bottom: 29px;
        }

        >:nth-child(5) {
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          display: flex;
          align-items: center;

          color: #485573;
          margin-bottom: 16px;
        }

        >:nth-child(6) {
          font-size: 14px;
          line-height: 150%;
          color: #666666;
          margin-bottom: 25px;
        }

        >:nth-child(7) {
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          /* identical to box height, or 16px */

          display: flex;
          align-items: center;

          color: #485573;
          margin-bottom: 16px;
        }

        >:nth-child(8) {
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;

          color: #666666;
        }
      }
    }

  }
}</style>
