<template>
  <div class="index">
    <!-- 头部导航 -->
    <Topbar skip1="UserInfo" skip2="Teamwork" skip3="FAQ" skip4="ActivityCenter" />
    <!-- 中间内容部分 -->
    <div v-if="!iosShowed" class="content">
      <div class="div1">
        <div class="div_logo">
          <v-img style="border-radius: 20px;"
            :src="BigImg(gameInfo.icon)"></v-img>
        </div>
        <div class="div_name">
          <div>{{gameInfo.name}}</div>
          <div>{{gameInfo.title}}</div>
          <div>
            <div v-for="(item,index) in gameInfo.tags"  :style="`color:${colors[index]};border: 1px solid ${colors[index]};`">{{item}}</div>
          </div>
        </div>
        <div class="div_download">
          <div class="ios" v-if="gameInfo.download_ios" @click="iosShowed=true"><img :src='require("@/assets/imgs/ios-1.png")' /> App Store</div>
          <div class="ios" v-if="gameInfo.download_android" @click="goDown(gameInfo.download_android)"><img :src='require("@/assets/imgs/android-0.png")' />Android </div>
           <div class="ios" v-if="gameInfo.download_windows" @click="goDown(gameInfo.download_windows)"><img :src='require("@/assets/imgs/pc-0.png")' />Window </div>
        </div>
      </div>
      <div class="swiper">
        <div style="--swiper-navigation-color: #fff; --swiper-pagination-color: #fff" class="swiper1">
          <div class="swiper-wrapper">
            <div v-if="gameInfo.video" class="swiper-slide">
              <video  :src="gameInfo.video" width="100%" height="auto"
                controls></video>
            </div>
            <div v-if="gameInfo.img1" class="swiper-slide" >
              <img :src="BigImg(gameInfo.img1)" alt=""/>
            </div>
            <div v-if="gameInfo.img2" class="swiper-slide">
              <img :src="BigImg(gameInfo.img2)" />
            </div>
            <div v-if="gameInfo.img3" class="swiper-slide">
              <img :src="BigImg(gameInfo.img3)" />
            </div>
            <div v-if="gameInfo.img4" class="swiper-slide">
              <img :src="BigImg(gameInfo.img4)" />
            </div>
            <div v-if="gameInfo.img5" class="swiper-slide">
              <img :src="BigImg(gameInfo.img5)" />
            </div>
          </div>

          <div class="swiper-button-prev"><img :src='require("@/assets/imgs/left.png")' /></div>
          <div class="swiper-button-next"><img :src='require("@/assets/imgs/right.png")' /></div>
        </div>
        <div thumbsSlider="" class="swiper2">
          <div class="swiper-wrapper">
            <div v-if="gameInfo.video" class="swiper-slide">
              <video :src="gameInfo.video" width="100%" height="auto"
                controls></video>
            </div>
            <div v-if="gameInfo.img1" class="swiper-slide" >
              <img :src="gameInfo.img1" alt=""/>
            </div>
            <div v-if="gameInfo.img2" class="swiper-slide">
              <img :src="gameInfo.img2" />
            </div>
            <div v-if="gameInfo.img3" class="swiper-slide">
              <img :src="gameInfo.img3" />
            </div>
            <div v-if="gameInfo.img4" class="swiper-slide">
              <img :src="gameInfo.img4" />
            </div>
            <div v-if="gameInfo.img5" class="swiper-slide">
              <img :src="gameInfo.img5" />
            </div>
          </div>
        </div>
      </div>
      <div class="description">
        <div>{{$t("遊戲介紹")}}</div>
        <div></div>
        <div v-html="gameInfo.explanation">
        </div>
        <div></div>
      </div>
      <div class="env">
        <div>{{$t("遊戲環境")}}</div>
        <div>IOS</div>
        <div v-text="gameInfo.ios_version"></div>
        <div>Android</div>
        <div v-text="gameInfo.android_version"></div>
      </div>
    </div>
    <div v-else class="content">
      <IOS @goDown="goDown" @hideIOS="iosShowed=false" :name="gameInfo.name" :title="gameInfo.title" :icon="gameInfo.icon" :url="gameInfo.download_ios"></IOS>
    </div>
    <!-- 底部栏 -->
    <Botbar />
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Botbar from "@/components/Botbar";
import IOS from "@/components/IOS";
import i18n from "@/i18n/index";



export default {
  components: {
    Topbar,
    Botbar,
    IOS
  },
  data() {
    return {
      iosShowed:false,
      gameInfo:{},
      colors:[
        "#4DADD6",
        "#9F66CB",
        "#EE4D08",
        "#4DADD6",
        "#9F66CB",
        "#EE4D08",
        "#4DADD6",
      ]
    };
  },
  mounted() {
    this.swiper2 = new Swiper(".swiper2", {
      spaceBetween: 10,
      slidesPerView: 5,
      freeMode: true,
      watchSlidesProgress: true,
    });
    this.swiper1 = new Swiper(".swiper1", {
      effect: "cards",
      grabCursor: true,
      cardsEffect: {
        perSlideOffset: 16,
        rotate: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: this.swiper2,
      },
    });
    this.getGameInfo();
  },
  methods: {
    getGameInfo(){
      var id= this.$route.query.id;
      this.$get("/Api/Home/GameInfo",{id}).then(res=>{
        res.data.explanation=decodeURIComponent(res.data.explanation)
        this.gameInfo=res.data
      })
    },
    ios(){
      this.iosShowed=true;
    },
    BigImg(item) {
      if(!item) return ""
      if (item.indexOf("-big") > 0) {
        this.bigimg = item;
      } else {
        var hz = item.slice(item.lastIndexOf("."));
        var img = item.substring(0, item.lastIndexOf("."));
        this.bigimg = img + "-big" + hz;
      }
      return this.bigimg;
    },
    goDown(url){
        window.location.href=url
    }
  },
};
</script>

<style lang="scss" scoped>
.index {
  margin-top: 60px;
  background: #F0F6F9;

  .content {
    font-family: 'PingFang SC';
    font-style: normal;

    position: relative;
    width: 1124px;
    margin: 60px auto 190px auto;

    .div1 {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      height: 236px;
      
      .div_logo {
        width: 218px;
        margin: 9px;
      }

      .div_name {
        width: 100%;
        text-align: left;
        position: relative;

        >:nth-child(1) {
          font-weight: 700;
          font-size: 24px;
          color: #485573;
        }

        >:nth-child(2) {
          font-weight: 400;
          font-size: 16px;
          color: #666666;
        }

        >:nth-child(3) {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          gap: 10px;
          position: absolute;
          bottom: 0;

          div {
            border: 1px solid #4DADD6;
            border-radius: 2px;
            color: #4DADD6;
            padding: 3px 15px;
          }

        }
      }


      .div_download {
        img {
          width: 22.45px;
        }

        font-size: 22px;
        color: #69789C;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        >:nth-child(1) {
          background: #05FFF0;
          color: black;
        }

        div {
          border: 1px solid #69789C;
          border-radius: 10px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 15px 16px;
          gap: 8px;
          width: 220px;
          height: 56px;
          border-radius: 10px;
          cursor: pointer;
        }
      }

    }


    .swiper {
      margin-top: 75px;

      .swiper1 {
        margin: 0 auto;
        width: 982px;

        .swiper-slide {
          height: 486px;
          border-radius: 10px;
        }
      }

      .swiper2 {
        box-sizing: border-box;
        margin-top: 26px;
        padding: 10px 0;

        .swiper-slide {
          filter: brightness(0.5);
          width: 213px;
          height: 93px;
        }

        .swiper-slide-thumb-active {
          filter: unset;
        }
      }

      .swiper1 .swiper-slide,
      .swiper2 .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;

        background-size: cover;
        background-position: center;
        border-radius: 10px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }

    swiper-slide.swiper-slide-prev,
    .swiper-slide.swiper-slide-next {
      filter: brightness(0.5);
    }

    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after {
      content: none;
    }


    .swiper-button-next:after,
    .swiper-rtl .swiper-button-prev:after {
      content: none;
    }

    .swiper-button-next {
      margin-right: -76px;
    }

    .swiper-button-prev {
      margin-left: -76px;
    }

    .swiper-button-next img,
    .swiper-button-prev img {
      width: 48px;
      height: auto;
    }

    .description {
      margin-top: 60px;

      >:nth-child(1) {
        margin-bottom: 2px;
        font-weight: 400;
        font-size: 22px;
        line-height: 31px;
        letter-spacing: -0.28px;
        color: #15ABBA;
        text-align: left;
      }

      >:nth-child(2),
      >:nth-child(4) {
        opacity: 0.6;
        border: 1px solid #9FB8C5;
      }

      >:nth-child(3) {
        margin: 34px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 40px;
        color: #666666;
        text-align: left;
      }

    }

    .env {
      margin-top: 45px;
      text-align: left;

      >:nth-child(1) {
        font-weight: 400;
        font-size: 16px;
        line-height: 33px;
        letter-spacing: -0.28px;
        color: #8F8DA5;
      }

      >:nth-child(2),
      >:nth-child(4) {
        font-weight: 400;
        font-size: 16px;
        line-height: 33px;

        letter-spacing: -0.28px;

        color: #05FFF0;
      }

      >:nth-child(3),
      >:nth-child(5) {
        font-size: 16px;
        line-height: 33px;
        letter-spacing: -0.28px;

        color: #485573;
      }
    }
  }

}
</style>