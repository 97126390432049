<template>
 
</template>

<script>
import i18n from "@/i18n/index";
import Recharge from "@/components/Recharge.vue";
import Chat from "@/components/Chat";
import NewChat from "@/components/NewChat";
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
    Recharge,
    Chat,
    NewChat,
  },
  data() {
    return {
    }
  },
  created() {
    this.$router.push({name:'UserInfo',query:{index:6}})
  },
  methods: {
 
  },
  watch: {
    
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/Recharge.scss";
</style>