import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store/index'
Vue.use(VueRouter);

const routes = [
  // 移动端路由跳转
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/login",
    name: "LogIn",
    component: () => import("@/views/LogIn.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/views/SignUp.vue"),
  },
  {
    path: "/forgetpassword",
    name: "ForgetPassword",
    component: () => import("@/views/ForgetPassword.vue"),
  },
  {
    path: "/Teamwork",
    name: "Teamwork",
    component: () => import("@/views/Teamwork.vue"),
  },
  {
    path: "/FAQ",
    name: "FAQ",
    component: () => import("@/views/FAQ.vue"),
  },
  {
    path: "/FindByAccountCode",
    name: "FindByAccountCode",
    component: () => import("@/views/FindByAccountCode.vue"),
  },

  {
    path: "/UserInfo",
    name: "UserInfo",
    component: () => import("@/views/UserInfo.vue"),
  },
  {
    path: "/Order",
    name: "Order",
    component: () => import("@/views/UserInfo.vue"),
  },
  {
    path: "/Recharge",
    name: "Recharge",
    component: () => import("@/views/Recharge.vue"),
  },
  {
    path: "/TOS",
    name: "TOS",
    component: () => import("@/views/TOS.vue"),
  },
  {
    path: "/Privacy",
    name: "Privacy",
    component: () => import("@/views/Privacy.vue"),
  },
  {
    path: "/ActivityCenter",
    name: "ActivityCenter",
    component: () => import("@/views/ActivityCenter.vue"),
  },
  {
    path: "/wxQRcode",
    name: "wxQRcode",
    component: () => import("@/views/wxQRcode.vue"),
  },
  {
    path: "/zfbQRcode",
    name: "zfbQRcode",
    component: () => import("@/views/zfbQRcode.vue"),
  },
  {
    path: "/gameDetails",
    name: "GameDetails",
    component: () => import("@/views/GameDetails.vue"),
  },
  {
    path: "/IOS",
    name: "IOS",
    component: () => import("@/views/IOS.vue"),
  }
];


const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};



export default router;
