<template>
  <div class="ios">
    <div class="div1">
      <div>App Store {{ $t("下載") }}</div>
      <div @click="$emit('hideIOS')"><img :src='require("@/assets/imgs/back.png")' /></div>
    </div>
    <div class="div2"></div>
    <div class="div3">
      <div>
        <div>
          <img :src="icon" />
        </div>
        <div>{{ name }}</div>
        <div>{{ title }}</div>
      </div>
      <div>
        <div>
          <div class="tit">{{$t("直接下載")}}</div>
          <div class="title_hr"></div>
          <div class="title_red">{{$t("ios設備下載方式")}}</div>
          <div class="content">{{$t("使用iOS設備或手機直接點擊【下載遊戲】，並依照頁面步驟進行遊戲安裝，若無法使用直接下載方式安裝，請嘗試使用【IPA下載】交依照Si誒eloa誒ly操作步驟遊戲安裝遊戲玩")}}</div>
          <div class="download" @click="$emit('goDown', url)">{{$t("下載遊戲")}}</div>
        </div>
        <div>
          <div class="tit">IPA</div>
          <div class="title_hr"></div>
          <div class="title_red">{{$t("ios版本安装方式调整说明")}}</div>
          <div class="content">{{$t("由于苹果政策调整使得原在手机设计下载服务受到影响，团队仍在积极寻找解决方案期望尽快恢复服务，现阶段请玩家参与以下作法于电脑上使用个人签证完成安装")}}
            <p><span>{{$t("使用Apple ID 签名安装的应用有效期为7天，7天后若您看到“App无法再使用”，您可以使用相同的Apple ID再次下载")}}</span></p>
          </div>
          <div class="download" @click="$emit('goDown', url)">{{$t("下載IPA遊戲安裝包")}}</div>
        </div>
        <div>
          <div class="tit">{{$t("操作步驟")}}</div>
          <div class="title_hr"></div>
          <div class="content">
            <p>{{$t("一，請到AppleP官網下載iTunes並確認是否連接您的iDevice（請勿使用Microsoft Store版本iTunes）")}}</p>
            <p>{{$t("二，在電腦上安裝 最新版本的Sideloadly.Sideloadly下載地址：")}}<a target="_blank" href="https://sideloadly.io/#download"
                style="color: #FF5285;">{{$t("官方下載")}}</a></p>
            <p>{{$t("三，查看安裝教程（提供影音版步驟）")}}</p>
            <video src="/ios.mp4" controls width="576px"></video>
            <p>{{$t("文字版本安裝說明")}}</p>
            <p>{{$t("一，Sideloadly IPA安裝教學：")}}<a target="_blank"
                href="https://telegra.ph/Sideloadly%E5%AE%89%E8%A3%9DIPA%E6%96%87%E4%BB%B6%E6%95%99%E5%AD%B8-03-02"
                style="color: #FF5285;">{{$t("點擊前往")}}</a></p>
            <p>{{$t("若您無法使用Sideloadly或安裝失敗，也可以嘗試使用Altstore下載安裝")}}</p>
            <p>{{$t("二，Altstore IPA安裝教學：")}}<a target="_blank"
                href="https://telegra.ph/AltStore-%E4%B8%8B%E8%BC%89%E6%95%99%E5%AD%B8-03-02"
                style="color: #FF5285;">{{$t("點擊前往")}}</a></p>
          </div>
        </div>
        <div>
          <div class="tit">Q&A</div>
          <div class="title_hr"></div>
          <div class="content">
            <span>{{$t("使用 Apple ID 簽名 IPA 文件也就是常說的“個人簽”，以下匯總使用Sideloadly IPA 簽名在使用時可能遇到的一些問題。")}}</span>
            <div v-for="(item, i) in qas" :key="i">
              <div class="question" @click="index = i">{{ item.question }}</div>
              <v-fade-transition mode="out-in">
                <div v-show="i == index" class="answer">{{ item.answer }}</div>
              </v-fade-transition>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/index";

export default {
  components: {

  },
  props: ["name", "title", "icon", "url"],
  data() {
    return {
      index: 0,
      qas: [
        {
          question: i18n.t("1.开启应用程式显示【需要启用开发者模式】提示讯息？"),
          answer:i18n.t("当您的设备系统升级到IOS16，需要前往设定-隐私与安全性页面，滑动至最底下的安全性开启开发都模式，开启后等待设备重新开机完毕，再次执行即可正常进入")
        },
        {
          question:i18n.t("2.Sideloadly支持哪個電腦系統版本？"),
          answer: i18n.t("Sideloadly可以在 Windows 7、8、10 和 11 上運行。為了獲得最佳效果，建議使用 Windows 10 或更高版本。➜Sideloadly可以在 macOS 10.12 Sierra 及更高版本上運行。為獲得最佳效果，建議使用 macOS Catalina、Big Sur、Monterey 及更高版本"), 
        },
        {
          question: i18n.t("3.Sideloadly無法顯示我的設備？"), 
          answer: i18n.t("如果發生這種情況，請嘗試重新啟動您的 PC，在您的設備連接時打開 iTunes，並確保您從 iDevice 的彈出窗口中點擊“信任”。然後再打開 Sideloadly。如果這沒有幫助，您也可以嘗試完全卸載然後重新安裝 iTunes。"), 
        },
        {
          question: i18n.t("4.裝已簽名的程式需要越獄嗎？"),
          answer: i18n.t("不需要。不論是使用證書簽名還是使用 Apple ID 簽名，安裝時都不要求設備越獄。"),
        },
        {
          question: i18n.t("5.用於簽名的 Apple ID 需要關閉雙重認證嗎？"),
          answer: i18n.t("不需要。不論 Apple ID 關閉或者開啟雙重認證，都可以用來簽名 IPA 文件，只不過已開啟雙重認證的 Apple ID 在第一次使用時需要進行驗證，之後使用時不需要再次驗證。"),
        },
        {
          question: i18n.t("6.簽名的有效期是多久？"), 
          answer: i18n.t("使用證書簽名的 IPA 文件，安裝後的使用時間取決於證書的有效期，如果在有效期內證書被吊銷，應用將無法再次打開，也就是常說的“掉簽”；使用 Apple ID 簽名安裝的應用有效期為 7 天。7天後，您可以使用相同的 Apple ID 再次下載。"),
        },
        {
          question: i18n.t("7.可以使用多少個應用程式？"),
          answer: i18n.t("iOS 7、8、9：您可以在設備上加載任意數量的應用程序。但是您的免費開發者帳戶有限制。在 iOS 10、11、12、13、14 及更高版本上，您的設備上只能同時安裝 3 個應用程式。"),
        }
      ]
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.ios {
  margin-top: 60px;
  background: #F0F6F9;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;

  .div1 {
    display: flex;
    justify-content: space-between;
    height: 36px;

    >:nth-child(1) {
      font-weight: 400;
      font-size: 36px;
      line-height: 100%;
      /* identical to box height, or 36px */

      display: flex;
      align-items: center;
      letter-spacing: -0.065em;

      color: #485573;
    }

    >:nth-child(2) {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 34px;
        height: 30px;
      }
    }


  }

  .div2 {
    margin-top: 20px;
    opacity: 0.6;
    border: 1px solid #9FB8C5;
  }

  .div3 {
    margin-top: 21px;
    display: flex;
    justify-content: space-between;

    >:nth-child(1) {

      width: 218px;
      text-align: left;

      >:nth-child(1) {
        padding: 9px;
        padding-top: 0px;
        height: 218px;

        img {
          width: 200px;
          border-radius: 20px;
        }
      }

      >:nth-child(2) {
        font-size: 20px;
        line-height: 100%;
        letter-spacing: 0.045em;
        color: #485573;
        margin-top: 6px;
        padding-left: 9px;
      }

      >:nth-child(3) {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        padding-left: 9px;
        margin-top: 3px;
        color: #666666;
      }
    }

    >:nth-child(2) {
      box-sizing: border-box;

      /* Auto layout */

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 27px 28px 120px;
      gap: 27px;

      width: 884px;

      border: 2px solid #9FB8C5;
      border-radius: 20px;

      .tit {
        font-size: 22px;
        line-height: 31px;
        /* identical to box height */

        display: flex;
        align-items: center;
        letter-spacing: -0.28px;

        color: #15ABBA;
      }

      .title_hr {
        width: 100%;
        opacity: 0.6;
        border: 1px solid #9FB8C5;
        margin: 4px 0 6px 0;
      }

      .title_red {
        font-size: 22px;
        line-height: 31px;
        /* identical to box height */

        display: flex;
        align-items: center;
        letter-spacing: -0.28px;
        margin-bottom: 15px;
        color: #FF5285;
      }

      .content {
        p {
          margin-bottom: 0px;
        }

        text-align: left;
        font-size: 16px;
        line-height: 40px;
        /* or 250% */

        letter-spacing: -0.28px;

        color: #666666;


        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;

        span {
          color: #485573;
        }

        .question {
          box-sizing: border-box;

          /* Auto layout */

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;

          margin: 0 auto;

          height: 58px;

          border: 1px solid #666D7D;
          border-radius: 10px;

          font-size: 16px;
          line-height: 40px;
          /* identical to box height, or 250% */

          letter-spacing: -0.28px;

          color: #666D7D;
          margin-top: 10px;
          cursor: pointer;
        }

        .answer {
          font-weight: 400;
          font-size: 16px;
          line-height: 40px;
          /* or 250% */

          letter-spacing: -0.28px;

          color: #666666;
        }
      }

      .download {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        color: #FFFFFF;

        width: 336px;
        height: 76px;

        background: linear-gradient(91.69deg, #F84077 16.39%, #F95E93 139.39%);
        border-radius: 10px;

        /* Inside auto layout */
        margin-top: 15px;
        flex: none;
        order: 2;
        flex-grow: 0;
        cursor: pointer;
      }

    }
  }

}
</style>