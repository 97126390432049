<template>
  <div class="ChangeLanguage">
    <v-menu offset-y :rounded="rounded">
      <template v-slot:activator="{ on, attrs }">
        <v-img v-bind="attrs" v-on="on"  class="lang" :src="require('@/assets/imgs/lang.png')"></v-img>
      </template>
      <v-list>
        <v-list-item style="min-height: 42px" link v-for="(item, index) in locales" :key="index"
          @change="changeLanuage(item)">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rounded: "lg",
      locale: "zh-TW", //使用i18n实例默认定义的语言
      locales: [
        {
          value: "en-US",
          text: "English",
        },
        {
          value: "zh-TW",
          text: "中文繁體",
        },
        {
          value: "vi-VN",
          text: "Việt Nam",
        },
        // {
        //   value: "th-TH",
        //   text: "Thái",
        // },
        {
          value: "ko",
          text: "한국어",
        },
      ],
    };
  },
  created() {
    var lang = localStorage.getItem("locale");
    this.locale = lang;
    this.$i18n.locale = lang;
  },
  methods: {
    changeLanuage(val) {
      this.locale = val.value;
      this.$i18n.locale = val.value;
      localStorage.setItem("locale", val.value);
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.ChangeLanguage {
  margin-left: 10px;

  .v-icon {
    color: #fff;
  }
  
  .lang{
    height: 34.567901611328125px;
    width: 35px;
    border-radius: 0px;
    cursor: pointer;
  }

  span {
    display: inline-block;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    width: 120px;
    background-color: #ba4a6c;
    color: #fff;
    border-radius: 20px;
  }
}
</style>