<template>
  <div class="wxQrcode">
    <div class="wxQrcode-content">
      <!-- 文字 -->
      <div class="text-content">
        <div class="line1">
          <img src="../assets/imgs/wxQRcode.png" alt="" />微信支付手机版
        </div>
        <div class="line2">长按保存到相册使用扫码完成支付</div>
        <!-- 图片 -->
        <div class="qrcode">
          <vue-qr v-if="url" :text="url" :margin="0" />
        </div>
        <div class="line3">
          提示新用户扫码会有风险提示属于正常情况手动解除即可
        </div>
        <div class="line4">
          提示:你可以将以上链接发到自己微信的聊天框（在微信顶部搜索框可以搜到自己的微信），即可点击进入支付
        </div>
        <div class="line5">
          <button class="btn1" @click="openwx()">
            截图保存后点击打开微信扫码
          </button>
          <button class="btn2" @click="checkPayStatus()">检测支付状态</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      url: "",
    };
  },
  created() {
    //判断get参数token
    this.url = this.$route.query.url;
  },
  methods: {
    openwx() {
      if (isAndroid || isIOS) {
        window.location = "weixin://";
      } else {
        this.$toast("請使用手機開啟微信");
      }
    },
    checkPayStatus() {
      var orderid = this.$getQueryString("order_id");
      this.$get("/api/Home/CheckOrder", {
        orderid: orderid,
      }).then((res) => {
        this.$toast(res.message);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wxQrcode {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #fff;
  padding: 0px 20px;
  .wxQrcode-content {
    border: 1px solid #2c71b4;
    .text-content {
      width: 100%;
      text-align: center;
      .line1 {
        padding: 10px 0;
        background-color: #2c71b4;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        display: flex;
        align-items: center;
        img {
          width: 30px;
        }
      }
      .line2 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #daeef5;
        color: rgb(216, 64, 64);
        padding: 10px 0;
        font-size: 16px;
      }
      .qrcode {
        width: 100%;
        padding: 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
      }
      .line3 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #daeef5;
        color: rgb(216, 64, 64);
        padding: 10px 20px;
        font-size: 16px;
        line-height: 1.5;
      }
      .line4 {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(216, 64, 64);
        padding: 10px 15px;
        font-size: 12px;
        line-height: 1.5;
      }
      .line5 {
        border-top: 1px solid rgb(226, 214, 214);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        .btn1 {
          background-color: #2c71b4;
          margin-right: 5px;
        }
        .btn2 {
          background-color: green;
        }
        button {
          border-radius: 5px;
          padding: 10px;
          font-size: 12px;
          color: #fff;
        }
      }
    }
  }
}
</style>