<template>
  <div class="chat">
    <!-- 問題類型 -->
    <div class="chat-content">
      <div class="content">
        <div class="select" style="margin-top: 36px;">
          <v-select
            v-model="select"
            hide-details=""
            :items="questionType"
            :label="$t('請選擇問題類型')"
            item-value="value"
            item-text="text"
            outlined
            return-object
          ></v-select>
        </div>
        <h3>{{ $t("您的問題") }}</h3>
        <!-- 文本域 -->
        <div class="text-area">
          <v-textarea
            class="box"
            v-model="textarea"
            height="250"
            :label="$t('請說明你的問題')"
            name="input-7-4"
            solo
            outlined
            hide-details
          ></v-textarea>
        </div>
      </div>
    </div>
    <div class="botbar">
      <!-- 待处理和处理中 -->
      <div class="unfinish">
        <h3 style="font-size: 14px;line-height: 20px;color: #8F8DA5;padding-left: 10px;">{{ $t("上傳憑證(最多5張截圖)") }}</h3>
        <div class="addImg-content">
          <div class="addImg">
            <div
              class="smallImg"
              v-for="(item, index) in pictureImage"
              :key="index"
            >
              <img @click.stop="showBigImg(item)" :src="item" alt="" />
              <v-icon @click="deleteImage(index)" size="20">mdi-close</v-icon>
            </div>
            <div class="upload">
              <v-file-input
                v-if="pictureImage.length < 5"
                v-model="imageFile"
                hide-details=""
                background-color="#fff"
                type="file"
                class="img-upload-input"
                solo
                id="img-upload-input"
                accept="image/jpg, image/jpeg, image/png, image/gif"
                prepend-icon="mdi-plus"
                @change="uoloadImage()"
              ></v-file-input>
            </div>
          </div>
        </div>
      </div>
      <!-- 提交按钮 -->
      <div class="btn">
        <v-btn
          @click="sendMessage()"
          block
          class="rounded-lg mt-1 mb-5 btn1"
          bottom
          >{{ $t("提交") }}</v-btn
        >
      </div>

      <!-- 查看大圖 -->
      <v-dialog v-model="dialog" max-width="360" max-height="700">
        <v-img
          contain
          class="elevation-7 rounded-lg"
          :src="bigImg"
          :lazy-src="smallImg"
          ><template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/index";
export default {
  data() {
    return {
      dialog: false,
      bigImg: null,
      smallImg: null,
      select: "",
      // 输入框绑定的值
      textarea: "",
      // 问题类型
      questionType: [],
      imageFile: null,
      pictureImage: [],
      avatar: require("@/assets/imgs/Frame.png"),
    };
  },
  created() {
    this.getQuestionType();
  },
  methods: {
    // 提交信息
    sendMessage() {
      if (!this.textarea) {
        this.$toast(i18n.t("請說明您的問題"));
        return;
      }
      if (!this.pictureImage.length) {
        this.$toast(i18n.t("請上傳你的憑證"));
        return;
      }
      if (!this.questionType) {
        this.$toast(i18n.t("請說明你的問題類型"));
        return;
      }

      this.$post("/Chat/App_NewSave", {
        type: this.select.value,
        message: this.textarea,
        files: this.pictureImage,
      }).then((res) => {
        if (res.code == 0) {
          this.textarea = "";
          this.pictureImage = [];
          this.$toast(i18n.t("資訊上傳成功"));
          this.$router.push("/UserInfo");
        }
      });
    },
    // 问题类型
    getQuestionType() {
      this.$get("/Chat/GetQuestionType").then((res) => {
        if (res.code == 0) {
          this.questionType = res.data;
        }
      });
    },
    //删除当前选中的照片
    deleteImage(index) {
      this.pictureImage.splice(index, 1);
    },
    // 上传图片
    uoloadImage() {
      if (this.imageFile == null) {
        return;
      }
      var formData = new window.FormData();
      formData.append("file", this.imageFile);
      this.$post("/Chat/App_Uploadfile", formData).then((res) => {
        if (res.code == 0) {
          if (this.pictureImage.length <= 4) {
            this.pictureImage.push(res.data.fileName);
          } else {
            this.$toast(i18n.t("最多五張圖片"));
          }
        }
      });
    },
    showBigImg(item) {
      if (item.indexOf("-big") > 0) {
        this.bigImg = item;
        this.smallImg = item;
      } else {
        var hz = item.slice(item.lastIndexOf("."));
        var img = item.substring(0, item.lastIndexOf("."));
        this.bigImg = img + "-big" + hz;
        this.smallImg = item;
      }
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>

.v-label {
    font-size: 14px !important;
    line-height: 20px !important;
    color: #9FB8C5 !important;
}

.chat {

  text-align: left;
  .chat-content {
    .content {
      overflow: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 18px;
      h3 {
        font-weight: 400;
font-size: 16px;
line-height: 20px;
margin-top: 38px;
margin-bottom: 18px;

color: #485573;
      }
      
      .text-area::v-deep {
        textarea {
          line-height: normal !important;
        }
        .v-input__slot {
          //border: 1px solid #000;
          fieldset{
        border-radius: 5px 5px 0px 0px;
    border-block-color: #9FB8C5;
      }
        }
      }
    }
  }
  .botbar {
    
    z-index: 50;

    // 未处理完成
    .unfinish {
      border: 1px solid #9FB8C5;
      border-top: none;
    border-radius: 0px 0px 10px 10px;
      margin-bottom: 30px;
      background-color: #fff;
      h3 {
        padding: 10px 0;
        text-align: left;
        font-size: 14px;
        color: #999999;
      }
      // 添加上传图片
      .addImg-content {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .addImg {
          display: inline-flex;
          align-items: center;
          padding: 10px;
          .smallImg {
            margin-bottom: 15px;
            position: relative;
            width: 60px;
            height: 60px;
            border-radius: 5px;
            border: 1px solid #d2d2d2;
            margin-right: 10px;
            flex-shrink: 1;
            flex-grow: 0;
            .v-icon {
              position: absolute;
              right: -10px;
              top: -10px;
              background-color: #f00;
              border-radius: 50%;
              color: #fff;
            }
          }
          img {
            width: 60px;
            height: 60px;
            display: block;
            border-radius: 5px;
          }
          .upload::v-deep {
            position: relative;
            width: 60px;
            height: 60px;
            margin-bottom: 15px;
            .v-input {
              height: 100%;
              width: 100%;
              .v-input__prepend-outer {
                margin: 0;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                z-index: 100;
                position: absolute;
                .v-icon {
                  width: 50px;
                  height: 50px;
                }
              }
              .v-input__control {
                width: 60px;
                height: 60px;
                flex-direction: unset;
                border: 1px solid #d2d2d2;
                .v-file-input__text {
                  display: none;
                }
                .v-input__append-inner {
                  display: none;
                }
              }
            }
          }
        }
      }

      .input-box::v-deep {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        .input {
          .v-input__slot {
            box-shadow: none;
            .v-label {
              font-size: 14px;
              color: #999999;
            }
          }
        }
        .icon {
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f84279;
          border-radius: 50%;
          transform: rotate(-45deg);
          .v-icon {
            margin-left: 5px;
            color: #fff;
          }
        }
      }
    }
    .btn1 {
      
height: 76px;

background: linear-gradient(91.69deg, #F84077 16.39%, #F95E93 139.39%);
box-shadow: 0px 15px 30px rgba(245, 86, 136, 0.1);
border-radius: 10px;

font-size: 18px;
line-height: 20px;

color: #FFFFFF;

    }
  }
}
</style>