<template>
  <div class="index">
    <!-- 头部导航 -->
    <Topbar skip1="UserInfo" skip2="Teamwork" skip3="FAQ" skip4="ActivityCenter" @order="index = 6" />
    <!-- 中间内容部分 -->
    <div class="content">
      <div class="div1">
        <div class="logo_div">
          <img :src='require("@/assets/imgs/person.png")' />
        </div>
        <div class="name">{{ userInfo.account }}</div>
        <div class="balance">{{ userInfo.point }}<span>{{ $t("點") }}</span></div>
        <div class="deposit_btn" @click="index = 5">
          <img :src='require("@/assets/imgs/deposit_icon.png")' />
          {{ $t("儲值") }}
        </div>
        <div v-for="(item,ind) in menuList" :key="item.id" @click="changePanel(item.id)" class="menu"
          :class="item.id == index ? 'actived' : ''">
          <img :class="`menu_icon_`+ind" :src="mIconURL(item)" alt="">
          <div>{{ item.name }}</div>
        </div>
        <div class="exit" @click="logout">
          <img :src='require("@/assets/imgs/icon_exit.png")' alt="">
          <span> {{ $t("退出登錄") }}</span>
        </div>
      </div>
      <div class="div2">
        <!--点数查询  -->
        <div v-if="index == 1" class="right-content1">
          <!-- 头部 -->
          <div class="tit">{{ $t("點數查詢") }}</div>
          <div class="balance">{{ $t("剩餘點數") }}<span>{{ orderList.point }}</span></div>
          <div class="hr"></div>
          <div class="t2">{{ $t("充值記錄") }}</div>
          <div class="t3">
            <div class="money">
              {{ $t("本月累計儲值金額") }}
              <span>{{ orderList.amountMonth }}</span>
            </div>
            <div class="point">
              {{ $t("本月累計儲值點數") }}
              <span>{{ orderList.amountMonth }}{{ $t("點") }}</span>
            </div>
          </div>
          <div class="hr"></div>
          <div class="content-list" id="content-list" @scroll="onScroll" style="height:610px;overflow: auto;">
            <div v-if="orderList.orders" id="content-list-ul">
              <div v-for="(item, index) in items" :key="index" class="record">
                <p class="createTime">{{ item.createTime }}</p>
                <div class="line-data">
                  <div class="line-left">
                    <span class="sp1">{{ item.payName }}</span>
                    <span class="sp2">{{ $t("訂單號") }}：{{ item.order_id }}</span>
                  </div>
                  <div class="line-right">
                    <span class="sp3">{{ item.real_point }}{{ $t("點") }}</span>
                    <span class="sp4">{{ $t("儲值金額") }}：{{ item.amount }}</span>
                    <span v-if="item.status == 0" class="unpaid">{{
                      $t("未支付")
                    }}</span>
                    <span v-if="item.status == 1" class="finished">{{
                      $t("已完成")
                    }}</span>
                    <span v-if="item.status == 2" class="notfinished">{{
                      $t("未完成")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 联系客服 -->
        <div v-if="index == 2 && chatStatus == -2" class="right-content2">
          <!-- 头部 -->
          <div class="tit">{{ $t("聯繫客服") }}</div>
          <div class="t2">
            <div class="d1">{{ $t("問題反饋") }}</div>
            <div class="d2" @click="chatStatus = -1">
              <img :src='require("@/assets/imgs/edit.png")' />
            </div>
          </div>
          <div class="t3"></div>
          <!-- 没有问题反馈显示空图片 -->
          <div class="d-flex flex-column justify-center align-center" style="height: 80%" v-if="!MesssageList.length">
            <img style="width: 200px" src="../assets/imgs/none.png" alt="" />
            <div style="font-size: 18px;color: #9dafb6;">{{ $t("您暫未反饋問題") }}</div>
          </div>
          <!-- 显示问题列表 -->
          <div v-else class="question-type" style="height:700px;overflow: auto;">
            <div v-for="(item, index) in MesssageList" :key="index" class="record"
              @click="toChat(item.status, item.titleId, item.typeId)">
              <p class="createTime">{{ item.createTime }}</p>
              <div class="line-data">
                <div class="left">
                  <div class="typeName">{{ item.typeName }}</div>
                  <div class="message">{{ item.message }}</div>
                </div>
                <div :class="'status_' + item.status">
                  <!-- 消息红点 -->
                  <span class="dot" v-if="item.count != 0">{{
                    item.count
                  }}</span>{{ item.status == 0 ? $t("未處理") : (item.status == 1 ? $t("已處理") : $t("處理中")) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 我的凭证 -->
        <div v-if="index == 3" class="right-content3">
          <div class="tit">{{ $t("我的憑證") }}</div>
          <div class="box">
            <div class="t2">{{ $t("帳號憑證") }}</div>
            <div class="t3">{{ $t("請截圖保存您的帳號憑證") }}</div>
            <div class="t4">
              <vue-qr v-if="text" :text="text" :margin="0" />
            </div>
            <div class="t5">{{ $t("為什麽要保存帳號憑證") }}？</div>
            <div class="t6">{{ $t(
              "如果帳號丟失,可在忘記密碼-帳號憑證找回上傳憑證或掃描憑證找回"
            ) }}？
            </div>
          </div>
        </div>
        <!-- 修改密码 -->
        <div v-if="index == 4" class="right-content4">
          <div class="tit">{{ $t("修改密碼") }}</div>

          <!-- 重置密码 -->
          <v-card elevation="0">
            <v-form ref="form" style="width: 583px;height: 439px;margin-top: 70px;">
              <v-text-field readonly color="info" outlined v-model="userInfo.account" prepend-inner-icon="mdi-account"
                class="rounded-lg"></v-text-field>
              <v-text-field color="info" prepend-inner-icon="mdi-lock" :placeholder="$t('請輸入舊密碼')" outlined clearable
                v-model="oldPassword" :type="'password'" :rules="[rules.required, rules.oldPassword]"
                class="rounded-lg"></v-text-field>
              <v-text-field color="info" prepend-inner-icon="mdi-lock" :placeholder="$t('請輸入密碼')" outlined clearable
                v-model="password" :type="'password'" :rules="[rules.required, rules.counter, rules.password]"
                class="rounded-lg"></v-text-field>
              <v-text-field color="info" prepend-inner-icon="mdi-lock" :placeholder="$t('請再次輸入密碼')" outlined clearable
                v-model="confirmPassword" :type="'password'" :rules="[rules.required, rules.confirmPassword]"
                class="rounded-lg"></v-text-field>
              <v-btn height="60" block class="rounded-lg mt-1 btn2" bottom @click="changePassword">
                {{ $t("確定") }}
              </v-btn>
            </v-form>
          </v-card>
        </div>
        <!-- 充值页面 -->
        <div v-if="index == 5" class="right-content5">
          <div class="tit">{{ $t("儲值") }}</div>
          <Recharge />
        </div>
        <!-- 我的預約 -->
        <div v-if="index == 6" class="right-content6">
          <div class="tit">{{ $t("我的預約") }}</div>
          <div class="games">
            <div class="info" v-for="item in bookingGames">
              <div class="left">
                <img :src="item.icon" />
              </div>
              <div class="right">
                <div>{{ item.name }}</div>
                <div>&nbsp;</div>
                <div style="cursor: pointer;" @click="booking(item)">{{ (item.status == 1 && item.is_show) ?
                  $t("下載") : $t("已预约") }}</div>
              </div>
            </div>
            <div class="d-flex flex-column justify-center align-center" style="height: 80%;width: 100%;" v-if="!bookingGames.length">
              <img style="width: 200px" src="../assets/imgs/none.png" alt="" />
              <div style="font-size: 18px;color: #9dafb6;">{{ $t("您暫未预约游戏") }}</div>
            </div>
          </div>
        </div>
        <!-- 提交问题页面 -->
        <div v-if="index == 2 && chatStatus == -1" class="right-content6">
          <div class="tit">{{ $t("聯繫客服") }}>{{ $t("問題反饋") }}</div>
          <NewChat />
        </div>
        <!-- 未处理 -->
        <div v-if="index == 2 && chatStatus == 0" class="right-content7">
          <div class="tit">{{ $t("聯繫客服") }}>{{ $t("問題反饋") }}><span style="color:#2539EC ;">{{ $t("待處理") }}</span>
            <img @click="chatStatus = -1" :src='require("@/assets/imgs/edit.png")' />
          </div>
          <Chat :status="status" :titleId="titleId" :typeId="typeId" />
        </div>
        <!-- 已处理 -->
        <div v-if="index == 2 && chatStatus == 1" class="right-content8">
          <div class="tit">{{ $t("聯繫客服") }}>{{ $t("問題反饋") }}><span style="color:#FF4206 ;">{{ $t("已處理") }}</span>
            <img @click="chatStatus = -1" :src='require("@/assets/imgs/edit.png")' />
          </div>
          <Chat :status="status" :titleId="titleId" :typeId="typeId" />
        </div>
        <!-- 处理中 -->
        <div v-if="index == 2 && chatStatus == 2" class="right-content9">
          <div class="tit">{{ $t("聯繫客服") }}>{{ $t("問題反饋") }}><span style="color:#2539EC ;">{{ $t("處理中") }}</span></div>
          <Chat :status="status" :titleId="titleId" :typeId="typeId" />
        </div>
      </div>
    </div>
    <Dialog ref="hintDialog" />
    <!-- 底部栏 -->
    <Botbar />
  </div>
</template>






<script>
import i18n from "@/i18n/index";
import Recharge from "@/components/Recharge.vue";
import Chat from "@/components/Chat";
import NewChat from "@/components/NewChat";
import VueQr from "vue-qr";
import Topbar from "@/components/Topbar";
import Botbar from "@/components/Botbar";

export default {
  components: {
    VueQr,
    Recharge,
    Chat,
    NewChat,
    Topbar,
    Botbar,
  },
  data() {
    return {
      // 问题反馈信息
      MesssageList: [],
      titleId: null,
      typeId: null,
      status: null,
      text: "none",
      // 订单数据
      orderList: [],
      searchData: {
        pageIndex: 1,
        pageSize: 10,
      },
      totalPageCount: 0,
      items: [],
      isAchiveBottom: false, //滚动条是否到底部标志
      chatStatus: -2,
      index: 1,
      // 用户信息
      userInfo: [],
      menuList: [
        {
          id: 1,
          name: i18n.t("點數查詢"),
          icon: "icon_point",
        },
        {
          id: 2,
          name: i18n.t("聯繫客服"),
          icon: "icon_chat",
        },
        {
          id: 3,
          name: i18n.t("我的憑證"),
          icon: "icon_qr",
        },
        {
          id: 4,
          name: i18n.t("修改密碼"),
          icon: "icon_pwd",
        },
        {
          id: 6,
          name: i18n.t("我的預約"),
          icon: "icon_booking",
        }
      ],
      bookingGames: [],
      // 修改密码
      //旧密码
      oldPassword: "",
      password: "",
      confirmPassword: null,
      rules: {
        required: (value) => !!value || i18n.t("必填項"),
        counter: (value) =>
          (value && value.length >= 6 && value.length <= 20) ||
          i18n.t("密碼必須為6-20位英文+數字"),
        password: (value) => {
          const pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
          return pattern.test(value) || i18n.t("密碼格式英文+數字6-20位");
        },
        confirmPassword: (value) =>
          value === this.password || i18n.t("兩次密碼不一致"),
      },
    };
  },
  created() {
    this.index = this.$route.query.index || 1;
    // 登录拿到个人信息
    this.getUserInfo();
    // 获取订单数据
    this.getOrderList();
  },
  methods: {
    booking(item) {
      if (item.is_show && item.status == 1) {
        this.$router.push({ name: 'GameDetails', query: { id: item.id } })
        return
      }
      this.$get("/api/Home/bookingGame", {
        gameId: item.id,
        booked: false
      }).then((res) => {
        this.getBookingGame()
      });
    },
    logout() {
      this.$toast(i18n.t("已退出登錄"));
      localStorage.removeItem("token");
      this.$router.push("/");
    },
    mIconURL(item) {
      if (item.id == this.index)
        return require(`@/assets/imgs/${item.icon}_1.png`);
      else
        return require(`@/assets/imgs/${item.icon}_0.png`);
    },
    toChat(status, titleId, typeId) {
      if (status == 0) {
        this.chatStatus = 0;
        this.status = status;
        this.titleId = titleId;
        this.typeId = typeId;
      }
      if (status == 1) {
        this.chatStatus = 1;
        this.status = status;
        this.titleId = titleId;
        this.typeId = typeId;
      }
      if (status == 2) {
        this.chatStatus = 2;
        this.status = status;
        this.titleId = titleId;
        this.typeId = typeId;
      }
    },
    // 获取消息列表
    getListData() {
      this.$get("/Chat/App_ListData").then((res) => {
        this.MesssageList = res.data;
      });
    },
    // 修改密码
    changePassword() {
      if (this.$refs.form.validate()) {
        this.$post("/api/Account/ChangePassword", {
          oldPassword: this.oldPassword,
          password: this.password,
          confirmPassword: this.confirmPassword,
          key: ""
        }).then((res) => {
          this.$refs.hintDialog.dialog2 = true;
          this.$refs.hintDialog.text1 = i18n.t("溫馨提示");
          this.$refs.hintDialog.text2 = i18n.t("您的密碼已修改，請重新登錄");
          this.$refs.hintDialog.skipOrder = "/";
          localStorage.removeItem("token");
        });
      }
    },

    onScroll() {
      var contentList = document.querySelector("#content-list");
      var contentList1 = document.querySelector("#content-list-ul");
      // 滑动高度
      var scrollTop = contentList.scrollTop;
      //可视区的高度
      var clientHeight = contentList.clientHeight;
      // 文档高度
      var documentHeight = contentList1.clientHeight;

      if (
        scrollTop + clientHeight > documentHeight - 50 &&
        !this.isAchiveBottom
      ) {
        this.isAchiveBottom = true;
        //延时触发数据加载
        setTimeout(() => {
          if (this.searchData.pageIndex + 1 <= this.totalPageCount) {
            this.searchData.pageIndex++;
          }
          this.isAchiveBottom = false;
        }, 500);
      }
    },
    // 获取订单数据
    getOrderList() {
      this.$getpay("/api/Home/Orders", {
        pageIndex: this.searchData.pageIndex,
        pageSize: this.searchData.pageSize,
      }).then((res) => {
        this.orderList = res.data;
        if (this.items) {
          this.items = this.items.concat(res.data.orders.items);
        } else {
          this.items = res.data.orders.items;
        }
        var count = Math.ceil(
          res.data.orders.totalCount / this.searchData.pageSize
        );
        this.totalPageCount = count == 0 ? 1 : count;
      });
    },
    // removeRecommend() {
    //   this.$router.push("/");
    // },
    // removeNewgame() {
    //   this.$router.push("/");
    // },
    // 获取用户信息
    getUserInfo() {
      this.$get("/api/Home/UserInfo").then((res) => {
        this.userInfo = res.data;
        // 生成二维码
        this.text = JSON.stringify({
          account: this.userInfo.account,
          key: this.userInfo.key,
        });
      });
    },
    changePanel(id) {
      if (id == 2)
        this.chatStatus = -2;
      this.index = id;
    },
    getBookingGame() {
      this.$get("/api/Home/getBookingGame").then((res) => {
        this.bookingGames = res.data;
      });
    },
    // 跳转我的凭证
    Mydetails() {
      this.$router.push({
        name: "AccountCode",
        params: {
          account: this.userInfo.account,
          key: this.userInfo.key,
        },
      });
    },
  },
  computed: {

  },
  mounted() { },
  watch: {
    index: {
      handler() {
        history.replaceState(null, null, "/#/UserInfo?index=" + this.index)
        if (this.index == 1) {
          this.items = [];
          this.searchData.pageIndex = 1;
          this.totalPageCount = 0;
          this.getOrderList();
        }
        if (this.index == 2) {
          this.getListData();
        }
        if (this.index == 6) {
          this.getBookingGame()
        }

      },
    },
    searchData: {
      handler() {
        if (this.searchData.pageIndex > 1) {
          this.getOrderList();
        }
      },
      deep: true, //深度监听对象属性
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  margin-top: 60px;
  background: #F0F6F9;
  
  .content {
    font-family: 'PingFang SC';
    font-style: normal;
    width: 1124px;
    margin: 60px auto 190px auto;
    display: flex;
    gap: 19px;

    .div1 {
      box-sizing: border-box;
      position: relative;
      width: 292px;
      height: 880px;
      background: rgba(255, 255, 255, 0.7);
      border: 1px solid #FFFFFF;
      backdrop-filter: blur(30px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .logo_div {
        width: 89.45px;
        height: 96px;
        margin-top: 24px;

        img {
          width: 85px;
          height: 85px;
          background: #FFFFFF;
          border: 2px solid #FFFFFF;
          border-radius: 100px;
        }
      }

      .name {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.28px;

        /* 主内容 */

        color: #666666;
      }

      .balance {
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        /* identical to box height */


        /* 主内容 */

        color: #485573;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          color: #666666;
        }
      }

      .deposit_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 32px;
        gap: 8px;
        margin-top: 15px;
        margin-bottom: 36px;
        cursor: pointer;
        width: 250px;
        height: 50px;

        background: linear-gradient(96.77deg, #12C9DC 17.84%, #0DCADE 96.04%);
        box-shadow: 0px 10px 20px #DFEEF7;
        border-radius: 10px;

        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.28px;

        color: #FFFFFF;

        img {
          width: 18px;
        }
      }

      .menu {
        width: 125px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        font-weight: 400;
        font-size: 18px;
        line-height: 15px;
        /* identical to box height, or 83% */
        margin-bottom: 38px;

        color: #8F8DA5;

        img {
          width: 20px;
          height: 20px;
          margin-right: 24px;
        }
        .menu_icon_1{
          width: 24px;
          height: 24px;
        }
        .menu_icon_4{
          width: 25px;
          height: 25px;
        }
      }


      .menu.actived {
        color: #00C7DB;
      }

      .exit {
        font-family: 'Source Han Sans CN';
        position: absolute;
        height: 15px;
        left: 27px;
        top: 839px;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 15px;
          height: 15px;
          margin-right: 6px;
        }

        span {

          font-weight: 400;
          font-size: 14px;


          color: #9FB8C5;


        }
      }

    }

    .div2 {

      box-sizing: border-box;

      width: 812px;
      height: 880px;

      background: rgba(255, 255, 255, 0.7);
      border: 1px solid #FFFFFF;
      backdrop-filter: blur(30px);
      border-radius: 20px;
      text-align: left;
      padding: 34px 38px 68px;

      .tit {

        font-weight: 400;
        font-size: 20px;
        line-height: 28px;

        color: #00C7DB;
        position: relative;

        img {
          width: 25px;
          height: 25;
          position: absolute;
          cursor: pointer;
          right: -14px;
          bottom: -14px;
        }
      }

      .right-content1 {
        .balance {
          font-size: 16px;
          line-height: 20px;
          color: #485573;
          margin-top: 29px;

          span {
            margin-left: 7px;
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 48px;

            text-align: right;

            color: #FF5285;
          }
        }

        .hr {
          opacity: 0.5;
          border: 1px solid #9FB8C5;
        }

        .t2 {
          margin-top: 26px;
          margin-bottom: 9px;
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;

          color: #485573;
        }

        .t3 {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          margin-bottom: 25px;
          color: #666666;
          display: flex;

          .money {
            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 15px;
              /* identical to box height, or 94% */

              text-align: right;

              /* 次标&BANNER */

              color: #F84E84;
              margin-right: 82px;
            }
          }

          .point {
            font-weight: 400;
            font-size: 14px;
            line-height: 15px;
            /* identical to box height, or 107% */


            /* 主内容 */

            color: #666666;

            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 15px;
              /* identical to box height, or 94% */

              text-align: right;

              /* 次标&BANNER */

              color: #F84E84;
            }
          }
        }

        .record {
          padding: 17px 20px;
          margin-top: 20px;

          background: #FFFFFF;
          box-shadow: 0px 8px 15px rgba(207, 222, 228, 0.3);
          border-radius: 10px;

          .createTime {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #8F8DA5;
          }

          .line-data {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .line-left {
              display: flex;
              flex-direction: column;

              .sp1 {
                font-weight: 400;
                font-size: 16px;
                line-height: 100%;
                margin-bottom: 10px;
                color: #8F8DA5;
              }

              .sp2 {
                font-weight: 400;
                font-size: 16px;
                line-height: 100%;
                color: #666666;
              }
            }

            .line-right {
              .sp3 {
                font-weight: 700;
                font-size: 18px;
                line-height: 100%;
                color: #F84279;
                margin-right: 12px;
              }

              .sp4 {
                margin-right: 10px;
                font-weight: 400;
                font-size: 12px;
                line-height: 100%;
                color: #666666;
              }

              .unpaid {
                padding: 6px 17px;
                font-size: 12px;
                line-height: 15px;
                color: #3FC61D;
                height: 27px;

                background: #FDF1CB;
                border-radius: 5px;
              }

              .finished {
                padding: 6px 17px;
                font-size: 12px;
                line-height: 15px;
                color: #3FC61D;
                height: 27px;

                background: #D3F8EC;
                border-radius: 5px;
              }

              .notfinished {
                padding: 6px 17px;
                font-size: 12px;
                line-height: 15px;
                color: #2D52AF;
                height: 27px;

                background: #DBE7FF;
                border-radius: 5px;
              }
            }
          }
        }
      }

      .right-content2 {
        .t2 {
          display: flex;
          justify-content: space-between;
          margin-top: 38px;

          .d1 {
            font-size: 16px;
            line-height: 20px;
            color: #485573;
          }

          .d2 {
            img {
              width: 25px;
              cursor: pointer;
            }
          }
        }

        .t3 {
          margin-top: 8px;
          margin-bottom: 10px;
          opacity: 0.5;
          border: 1px solid #9FB8C5;
        }

        .record {
          padding: 20px 20px;
          margin-top: 20px;

          background: #FFFFFF;
          box-shadow: 0px 8px 15px rgba(207, 222, 228, 0.3);
          border-radius: 10px;

          .createTime {
            font-weight: 400;
            font-size: 14px;
            line-height: 15px;
            color: #8F8DA5;
          }

          .line-data {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
              display: flex;

              .typeName {
                font-size: 16px;
                line-height: 20px;
                margin-right: 20px;
                color: #485573;
              }

              .message {
                font-size: 16px;
                line-height: 20px;
                color: #8F8DA5;
                width: 400px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .dot {
              width: 14px;
              text-align: center;
              line-height: 14px;
              height: 14px;
              border-radius: 50%;
              background-color: #dd524d;
              font-size: 12px;
              color: #fff;
            }

            .status_0 {
              padding: 0 10px;
              height: 27px;
              background: #FCF1DD;
              border-radius: 5px;
              font-size: 14px;
              line-height: 15px;
              color: #CF9D41;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .status_1 {
              padding: 0 10px;
              height: 27px;
              background: #D1FAEC;
              border-radius: 5px;
              font-size: 14px;
              line-height: 15px;
              color: #3FC61D;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .status_2 {
              padding: 0 10px;
              height: 27px;
              background: #DBE7FF;
              border-radius: 5px;
              font-size: 14px;
              line-height: 15px;
              color: #1D6AC6;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .right-content3 {
        .box {
          width: 370px;
          height: 497px;
          margin: 63px auto;
          display: flex;
          flex-direction: column;
          align-items: center;

          .t2 {
            font-size: 18px;
            line-height: 20px;

            color: #485573;
            margin-bottom: 3px;
          }

          .t3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;

            color: #F84279;
            margin-bottom: 28px;
          }

          .t4 {
            width: 327px;
            padding: 24px;
            background: #FFFFFF;
            box-shadow: 0px 10px 20px rgba(136, 136, 136, 0.1);
            border-radius: 10px;
            margin-bottom: 51px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 270px;
              height: 270px;
            }

          }

          .t5 {
            font-size: 16px;
            line-height: 20px;

            color: #485573;
          }

          .t6 {
            font-size: 12px;
            line-height: 20px;

            color: #485573;
          }
        }
      }

      .right-content4 {
        >:nth-child(2) {
          display: flex;
          flex-direction: column;
          align-items: center;

          button {
            background: linear-gradient(91.69deg, #F84077 16.39%, #F95E93 139.39%);
            box-shadow: 0px 15px 30px rgba(245, 86, 136, 0.1);
            border-radius: 10px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;

            color: #FFFFFF;

          }
        }
      }

      .right-content6 {
        .games {
          margin-top: 50px;
          display: flex;
          gap: 5%;
          justify-content: flex-start;
          flex-wrap: wrap;
          flex-direction: row;
          padding: 0 25px;

          .info {
            padding: 11px 12px;
            width: 30%;
            height: 88px;
            background: linear-gradient(126.84deg, #EBEAFE 26.93%, #FCE5EC 88.01%);
            border: 1px solid #FFFFFF;
            border-radius: 10px;
            display: flex;
            margin-bottom: 20px;

            .left {
              img {
                width: 64px;
                margin-right: 8px;
                box-shadow: 0px 10px 20px #D9D0E2;
                border-radius: 10px;
              }
            }

            .right {
              display: flex;
              flex-direction: column;

              >:nth-child(1) {
                font-family: 'Source Han Sans CN';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 20px;
                color: #485573;
              }

              >:nth-child(2) {

                font-family: 'Source Han Sans CN';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                color: #A4A2B8;
              }

              >:nth-child(3) {

                height: 20px;

                background: #F84E84;
                border-radius: 5px;

                font-size: 16px;
                line-height: 20px;

                text-align: center;

                color: #FFFFFF;

              }
            }
          }
        }
      }

    }

  }


}
</style>