<template>
  <v-app id="app">
    <router-view v-if="isRouterAlive" />
    <!-- 未成年弹窗提示 -->
    <modal />
  </v-app>
</template>
<script>
import modal from "@/components/18modal";
import Vue from "vue";
export default {
  components: {
    modal,
  },
  name: "App",
  data() {
    return {
      isRouterAlive: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    //判断移动端还是pc端
    //<!--_isMobile方法-->
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  beforeCreate() {
    if (!localStorage.getItem("locale")) {
      localStorage.setItem("locale", "zh-TW");
    }
  },
  created() {

    var u = navigator.userAgent;
    window.isWebApp = window.navigator.standalone;
    window.isLine = u.toLocaleLowerCase().indexOf("Line") > -1;
    window.isApp = u.toLocaleLowerCase().indexOf("html5plus") > -1;
    window.isMac = u.toLocaleLowerCase().indexOf("Macintosh") > -1;
    window.isWin = u.toLocaleLowerCase().indexOf("Windows") > -1;
    window.isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    window.isAndroid =
      u.toLocaleLowerCase().indexOf("android") > -1 || u.indexOf("linux") > -1;
    window.isWechat = u.toLocaleLowerCase().indexOf("micromessenger") > -1;
  },
  mounted: function () {},
};
</script>
<style lang="scss">
html {
  /* 禁用html的滚动条，因为用的无框架窗口，默认就会有一个滚动条，所以去掉 */
  overflow-y: auto;
}
body {
  font-family: 微软雅黑；;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 0px; /*滚动条宽度*/
  height: 8px; /*滚动条高度*/
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* !*滚动条的背景区域的圆角*! */
  border-radius: 10px;
  /* !*滚动条的背景颜色*! */
  /* background-color: red; */
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 99px; /*滚动条的圆角*/
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* !*滚动条的背景颜色*!*/
  /* background-color: green; */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}
.v-dialog {
  margin: 0;
  border-radius: 15px;
  box-shadow: unset !important;
}
.v-dialog .d-hintImg {
  padding: 20px 10px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-dialog .d-hintImg img {
  display: block;
  width: 110px;
  height: 110px;
}
.v-dialog .d-text1 {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
  color: #333333;
}
.v-dialog .d-text2 {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin: 10px 0;
  color: #333333;
}

.v-list .v-list-item__title {
  font-size: 18px !important;
}
.v-card__title {
  font-size: 18px !important;
  font-weight: 800 !important;
  line-height: 1rem !important;
}
.v-card__text {
  padding: 0 40px !important;
}
.v-card__text p {
  text-align: left;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}
.v-card__actions .v-btn {
  font-size: 18px !important;
  color: #fff !important;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
